import { Injectable } from '@angular/core';
import { ContactDetails } from '../model/contact-details';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ErrorHandlerService } from '../../shared/service/error-handler.service';
import { ActionResponse } from 'nc-utils';
import { EnvironmentLoader } from '../../core/config/environment-loader';

/**
 * Service responsible for verification of persons "contact" details.
 * It invokes API methods to send, resend and verify "contact" details.
 */
@Injectable({
	providedIn: 'root',
})
export class VerificationService {
	constructor(private http: HttpClient, private errorHandler: ErrorHandlerService, private environmentLoader: EnvironmentLoader) {}

	private readonly API_URL = this.environmentLoader.getEnvironment().API_URL;

	/**
	 * Sends SMS code to the phone number provided in @UserContactInformation
	 * @param contact Data submitted from the form
	 * @param language Client's language
	 */
	sendSMS(contact: ContactDetails, language: string): Observable<ActionResponse> {
		const request = { model: contact, language };
		const url = `${this.API_URL}/verification/send-sms`;
		return this.http.post<ActionResponse>(url, request);
	}

	/**
	 * Resends SMS code to the phone number provided in the @sendSMS request (previous).
	 * @param language Client's language
	 */
	resendSMS(language: string): Observable<ActionResponse> {
		const url = `${this.API_URL}/verification/resend-sms`;
		return this.http.post<ActionResponse>(url, { language });
	}

	/**
	 * Sends @code form the input form for verification.
	 * @param code Value from the input filed to be checked
	 * @param language Client's language
	 */
	verify(code: string, language: string): Observable<ActionResponse> {
		const url = `${this.API_URL}/verification/verify-code`;
		return this.http.post<ActionResponse>(url, { code, language });
	}
}
