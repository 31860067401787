<form fxLayout="column" fxFill [formGroup]="formGroup">
	<h3>
		<nc-label id="title" [value]="title"></nc-label>
	</h3>
	<nc-toggle-buttons id="gendersToggleButtons" [formGroup]="formGroup" label="gender" formControlName="gender" [options]="genders">
	</nc-toggle-buttons>
	<nc-input id="firstName" [formGroup]="formGroup" formControlName="firstName" label="firstName"></nc-input>
	<nc-input id="lastName" [formGroup]="formGroup" formControlName="lastName" label="lastName"></nc-input>
	<nc-input id="company" [formGroup]="formGroup" formControlName="company" label="company"></nc-input>
	<nc-input
		id="contractId"
		[formGroup]="formGroup"
		formControlName="contractId"
		label="PublicRequestEmailTellco_ContractID"
		type="number"
	></nc-input>
	<nc-input
		id="phone"
		[formGroup]="formGroup"
		formControlName="phone"
		label="phone"
		maxLength="15"
		isPhoneNumber="true"
		(keyup)="onPhoneCheck($event)"
	></nc-input>
	<nc-input id="email" [formGroup]="formGroup" formControlName="email" label="email"></nc-input>
	<nc-textarea id="comment" [formGroup]="formGroup" formControlName="comment" label="comment" maxLength="300"></nc-textarea>
	<div id="submitPart" fxLayout="row">
		<nc-button id="cancel" label="cancel" (click)="onCancel()"></nc-button>
		<nc-button id="submitForm" label="submitForm" [disabled]="formGroup.invalid" (click)="submit()"></nc-button>
	</div>
</form>
