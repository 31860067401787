import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';

import { AppComponent } from './app.component';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { LabelTranslateLoader } from './shared/service/label-translate-loader';
import { StoreModule } from '@ngrx/store';
import { appMetaReducers, appReducers } from './state/app.state.persistence';
import { SharedModule } from './shared/shared.module';
import { AppRoutingModule } from './app-routing.module';
import { UserModule } from './user/user.module';
import { EffectsModule } from '@ngrx/effects';
import { ErrorMessageMap } from './shared/error/error-message-map';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RequestHttpInterceptor } from './shared/http/request-http-interceptor.service';
import { DocumentModule } from './document/document.module';
import { ReadyService } from './shared/service/ready.service';
import { ErrorHandlerService } from './shared/service/error-handler.service';
import { ErrorResponseHttpInterceptor } from './shared/http/error-response-http-interceptor.service';
import { EnvironmentLoader } from './core/config/environment-loader';

@NgModule({
	declarations: [AppComponent],
	imports: [
		SharedModule,
		BrowserModule,
		BrowserAnimationsModule,
		HttpClientModule,
		EffectsModule.forRoot([]),
		StoreModule.forRoot(appReducers, { metaReducers: appMetaReducers }),
		TranslateModule.forRoot({
			defaultLanguage: 'de',
			loader: {
				provide: TranslateLoader,
				useClass: LabelTranslateLoader,
				deps: [HttpClient, ReadyService, ErrorHandlerService, EnvironmentLoader],
			},
		}),
		UserModule,
		DocumentModule,
		AppRoutingModule,
	],
	providers: [
		{ provide: 'ValidationErrorMap', useValue: ErrorMessageMap },
		{ provide: HTTP_INTERCEPTORS, useClass: RequestHttpInterceptor, multi: true },
		{ provide: HTTP_INTERCEPTORS, useClass: ErrorResponseHttpInterceptor, multi: true },
	],
	bootstrap: [AppComponent],
})
export class AppModule {}
