import { Injectable } from '@angular/core';
import { Environment } from './environment';

@Injectable({
	providedIn: 'root',
})
export class EnvironmentLoader {
	private environment: Environment;

	private readonly OK = 200;
	private readonly GET = 'GET';
	private readonly CONFIG_URL = 'app/assets/app-config.json';

	private load(): void {
		const request = new XMLHttpRequest();
		request.open(this.GET, this.CONFIG_URL, false);
		request.send();

		this.environment = request.status === this.OK ? JSON.parse(request.response) : new Environment();
	}

	getEnvironment(): Environment {
		if (!this.environment) {
			this.load();
		}

		return this.environment;
	}
}
