import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ReadyService } from './ready.service';
import { UnauthorizedAccessDialogComponent } from '../component/dialog/unauthorized-access-dialog/unauthorized-access-dialog.component';
import { DialogConfigurationUtils, DialogService, HttpStatus } from 'nc-utils';
import { NcInformationDialogComponent } from 'nc-information-dialog';

/**
 * Error handling support.
 * It contains method for error and failed responses.
 */
@Injectable({
	providedIn: 'root',
})
export class ErrorHandlerService {
	constructor(private router: Router, private http: HttpClient, private dialogService: DialogService, private readyService: ReadyService) {}

	/**
	 * Handler for case when back-end is not available.
	 * @param error Http response error object
	 * @param language Chosen language of the application
	 */
	unavailable<TResponse>(error: HttpErrorResponse, language: string): Observable<TResponse> {
		this.readyService.notReady();
		this.router.navigateByUrl('/unavailable');
		return this.http.get<TResponse>(`/assets/unavailable/i18n/${language}.json`);
	}

	/**
	 * Common handler for http requests.
	 * It returns completed observable of the expected response type.
	 * Also, it prints error to the console
	 * @param error Http error request
	 */
	processHttpError(error: HttpErrorResponse): void {
		this.dialogService.closeAll();
		switch (error.status) {
			case HttpStatus.CONNECTION_REFUSED:
				this.router.navigateByUrl('/unavailable');
				break;
			case HttpStatus.BAD_REQUEST:
			case HttpStatus.INTERNAL_SERVER_ERROR:
				this.dialogService.open(NcInformationDialogComponent, DialogConfigurationUtils.error('server.error.message.publicForm'));
				break;
			case HttpStatus.FORBIDDEN:
			case HttpStatus.UNAUTHORIZED:
				const details = DialogConfigurationUtils.security('security.unauthorized.access');
				this.dialogService
					.open(UnauthorizedAccessDialogComponent, details)
					.afterClosed()
					.subscribe((result) => {
						this.router.navigateByUrl('/');
					});
				break;
			default:
				console.log('UNHANDLED STATUS: ' + error.status);
				break;
		}
	}
}
