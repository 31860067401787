import { Component, Input } from '@angular/core';
import { CaseReferenceDetails } from '../../model/case-reference-details/case-reference-details';
import { AbsenceTypeEnumeration } from '../../model/enumerations/absence-type.enumeration';
import { AbsenceAllSubTypeEnumeration } from '../../model/enumerations/absence-all-sub-type.enumeration';

@Component({
	selector: 'ea-case-specification',
	templateUrl: './case-specification.component.html',
	styleUrls: ['./case-specification.component.scss'],
})
export class CaseSpecificationComponent {
	@Input() caseDetails: CaseReferenceDetails;

	getType = (code: string) => AbsenceTypeEnumeration[code];
	getSubType = (code: string) => AbsenceAllSubTypeEnumeration[code];
}
