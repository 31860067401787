import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { State } from '../state/absence.state';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AbsencePageAction, AbsenceSelectAction } from '../state/action';
import { UntypedFormBuilder } from '@angular/forms';
import { Absence } from '../model/absence';
import {
	caseReferenceRequired,
	commentOrDocumentAreRequired,
	employeeRequired,
	incapacityPercentageRange,
	numberOfChildrenRequired,
	validateEmploymentRelationship,
	validateTemporaryEmployment,
	validFromBeforeValidTo,
	validFromInContractRange,
	validFromRequired,
	validToInContractRange,
} from '../validation/absence-validators';
import { Company } from '../model/company';
import { takeUntil, withLatestFrom } from 'rxjs/operators';
import { CanDeactivateFormComponent } from 'nc-shared';
import { UserPageAction } from '../../user/state/action';
import { NcDocumentsService } from 'nc-documents';
import { Employee } from '../model/employee';

@Component({
	templateUrl: './absence.component.html',
	styleUrls: ['./absence.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AbsenceComponent extends CanDeactivateFormComponent implements OnInit {
	absence$: Observable<Absence>;
	company$: Observable<Company>;
	employee$: Observable<Employee>;

	constructor(
		private store: Store<State>,
		private formBuilder: UntypedFormBuilder,
		private documentService: NcDocumentsService,
		private changeDetectorRef: ChangeDetectorRef
	) {
		super();
	}

	ngOnInit(): void {
		this.formGroup = this.formBuilder.group(
			{
				documents: this.formBuilder.array([]),
				forcedNew: [false],
				temporaryContract: [false],

				companyId: null,
				refEmployee: [null],
				employeeNeoId: '',
				employeeEasyTempId: '',
				pkProInsuranceExitDate: [''],
				pkProInsuranceEntryDate: [''],

				referenceNumber: [null],

				hasDailySicknessBenefitsInsurance: ['no'],
				dailySicknessBenefitsInsuranceCompany: [''],
				dailySicknessBenefitsInsuranceSinisterReference: [''],

				hasAccidentInsurance: ['no'],
				accidentInsuranceCompany: [''],
				accidentInsuranceSinisterReference: [''],

				hasChildrenUnder18orInEducationUpTo25: ['no'],
				numberOfChildren: ['0'],

				pregnancyDueDate: [null],
				absenceType: ['SICKNESS'],
				absenceSubType: ['UNKNOWN'],

				validFrom: [null],
				validTo: [null],
				percentage: [''],
				comment: [''],

				absenceCaseType: ['NEW'],
				categoryType: ['REGULAR'],

				employmentStartDate: [null],
				employmentEndDate: [null],
				employmentPercentage: [''],
				employmentAnnualSalary: [''],

				firstDeploymentDate: [null],
				firstDeploymentRegistration: ['REGISTRATION_FROM_1ST_DAY'],
				firstDeploymentSubject: ['SUBJECT_TO_DUTY_OF_MAINTENANCE'],
				lastDeploymentDate: [null],
				companyType: [''],
			},
			{
				validators: [
					caseReferenceRequired(),
					employeeRequired(),
					validFromInContractRange(),
					validToInContractRange(),
					numberOfChildrenRequired(),
					incapacityPercentageRange(),
					validFromBeforeValidTo(),
					validFromRequired(),
					validateEmploymentRelationship(),
					validateTemporaryEmployment(),
					commentOrDocumentAreRequired(),
				],
			}
		);

		this.store.dispatch(AbsencePageAction.fetchCompany());
		this.absence$ = this.store.select(AbsenceSelectAction.getAbsence);
		this.company$ = this.store.select(AbsenceSelectAction.getCompany);
		this.employee$ = this.store.select(AbsenceSelectAction.getEmployee);

		this.company$.subscribe((company) => {
			this.formGroup.get('companyId').patchValue(company.id);
		});

		this.employee$.subscribe((employee) => {
			this.formGroup.get('employeeEasyTempId').patchValue(employee.easyTempId);
			this.formGroup.get('employeeNeoId').patchValue(employee.neoId);
		});

		this.absence$.subscribe((absence) => {
			this.formGroup.get('pkProInsuranceExitDate').patchValue(absence.pkProInsuranceExitDate);
			this.formGroup.get('pkProInsuranceEntryDate').patchValue(absence.pkProInsuranceEntryDate);
		});

		this.store
			.select(AbsenceSelectAction.getErrors)
			.pipe(takeUntil(this.destroy$))
			.subscribe((errors) => {
				if (errors) {
					Object.keys(errors).forEach((key) => {
						const control = this.formGroup.get(key);
						if (control) {
							control.setErrors({ [errors[key].code]: null });
							control.markAsTouched();
							this.changeDetectorRef.markForCheck();
						}
					});
				}
			});

		this.store
			.select(AbsenceSelectAction.getSwitchedToProlongation)
			.pipe(takeUntil(this.destroy$), withLatestFrom(this.store.select(AbsenceSelectAction.getAbsenceReferenceNumber)))
			.subscribe(([isSwitchedToProlongation, referenceNumber]) => {
				if (isSwitchedToProlongation) {
					this.formGroup.get('absenceCaseType').patchValue('PROLONGATION');
					this.formGroup.get('referenceNumber').patchValue(referenceNumber);
				}
			});

		this.company$.subscribe((value) => this.formGroup.get('companyType').patchValue(value.fullDetails.type));
	}

	dispatchSubmitAction = (): void => {
		const absence = this.formGroup.getRawValue();
		absence.documents = this.documentService.getDocuments(this.formGroup);
		this.store.dispatch(AbsencePageAction.create({ absence }));
	};

	onCancel = (): void => {
		this.store.dispatch(UserPageAction.cancel());
	};
}
