import { createAction, props } from '@ngrx/store';
import { Company } from '../../model/company';
import { Employee } from '../../model/employee';
import { ErrorDetails, SaveResponse } from 'nc-utils';
import { Option } from 'nc-shared';

export const createSuccess = createAction('[Absence API] Create absence success', props<{ response: SaveResponse }>());
export const createFailed = createAction('[Absence API] Create absence failed', props<{ response: SaveResponse }>());

export const cancelSuccess = createAction('[Absence API] Cancel absence success');
export const cancelFailed = createAction('[Absence API] Cancel absence failed');

export const fetchCompanySuccess = createAction('[Absence API] Get company details', props<{ company: Company }>());
export const fetchEmployeesSuccess = createAction(
	'[Absence API] Fetch employees success for new case',
	props<{
		employees: Option[];
	}>()
);
export const searchCompanySuccess = createAction(
	'[Absence API] Fetch employees with company id success for new case',
	props<{
		employeesWithCompanyCode: Option[];
	}>()
);
export const fetchEmployeeSuccess = createAction('[Absence API] Fetch employees', props<{ employee: Employee }>());

export const setErrors = createAction('[Absence API] Fetch employees', props<{ errors: { [key: string]: ErrorDetails } }>());
