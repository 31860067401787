import { TranslateLoader } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { ErrorHandlerService } from './error-handler.service';
import { ReadyService } from './ready.service';
import { EnvironmentLoader } from '../../core/config/environment-loader';

/**
 * @TranslateLoader is part of the @ngx-translate and it allows us to load data from the REST API
 */
@Injectable({ providedIn: 'root' })
export class LabelTranslateLoader implements TranslateLoader {
	constructor(
		private httpClient: HttpClient,
		private readyService: ReadyService,
		private errorHandler: ErrorHandlerService,
		private environmentLoader: EnvironmentLoader
	) {}

	getTranslation(language: string): Observable<any> {
		this.readyService.ready();
		return this.httpClient
			.get<any>(`${this.environmentLoader.getEnvironment().API_URL}/i18n/${language}`)
			.pipe(catchError((error) => this.errorHandler.unavailable<any>(error, language)));
	}
}
