<div class="app-container" [class.app-is-mobile]="mobile$ | async" *ngIf="loading$ | async; else loading">
	<mat-sidenav-container #sidenavContainer class="app-sidenav-container" autosize *ngIf="language$ | async as language">
		<mat-sidenav #sidenav [opened]="!(mobile$ | async)" [fixedInViewport]="mobile$ | async" [mode]="(mobile$ | async) ? 'over' : 'side'">
			<ea-logo url="../assets/images/logo-tellco.png" width="200" height="60"></ea-logo>
			<mat-nav-list class="document-links">
				<ea-document-link
					[language]="language"
					label="caseManagement"
					icon="picture_as_pdf"
					type="CASE_MANAGEMENT"
					[sidenav]="sidenavContainer"
				>
				</ea-document-link>
				<ea-document-link [language]="language" label="BGM" icon="picture_as_pdf" type="BGM" [sidenav]="sidenavContainer"> </ea-document-link>
			</mat-nav-list>
			<ea-contact-card></ea-contact-card>
		</mat-sidenav>

		<mat-sidenav-content id="SidebarContent">
			<mat-toolbar class="app-toolbar toolbar-line" id="Toolbar">
				<button mat-icon-button (click)="sidenavButtonClick()">
					<ea-banner></ea-banner>
					<mat-icon *ngIf="isSidenavOpen">arrow_back</mat-icon>
					<mat-icon *ngIf="!isSidenavOpen">arrow_forward</mat-icon>
				</button>
				<span class="app-title">{{ title$ | async }}</span>
				<span class="app-spacer"></span>
				<nc-language-picker [id]="'language'" [active]="language" [onChange]="onLanguageChange" [languages]="['de', 'en', 'fr', 'it']">
				</nc-language-picker>
			</mat-toolbar>
			<router-outlet #outlet="outlet"></router-outlet>
		</mat-sidenav-content>
	</mat-sidenav-container>
</div>

<ng-template #loading>
	<nc-splash-screen image="assets/images/logo-tellco.png"></nc-splash-screen>
</ng-template>
