<div *ngIf="caseDetails && caseDetails.code" fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" class="case-specification">
	<span>
		<span class="case-specification-label">{{ 'absenceType' | translate }}</span
		>: <i>{{ getType(caseDetails.absenceType) | translate }}</i>
	</span>
	&nbsp; &nbsp;
	<ng-container [ngSwitch]="caseDetails.absenceType">
		<span *ngSwitchCase="'PREGNANCY'">
			<span class="case-specification-label">{{ 'pregnancyDueDate' | translate }}</span
			>: <i>{{ caseDetails.pregnancyDueDate }}</i>
		</span>
		<span *ngSwitchDefault>
			<span class="case-specification-label">{{ 'absenceSubType' | translate }}</span
			>: <i>{{ getSubType(caseDetails.absenceSubType) | translate }}</i>
		</span>
	</ng-container>
</div>
