import { enableProdMode, Injector } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { EnvironmentLoader } from './app/core/config/environment-loader';

const injector = Injector.create({ providers: [{ provide: EnvironmentLoader, deps: [] }] });
const environmentLoader = injector.get(EnvironmentLoader);

if (environmentLoader.getEnvironment().production) {
	enableProdMode();
}

platformBrowserDynamic()
	.bootstrapModule(AppModule)
	.catch((err) => console.error(err));
