/**
 * Validation is triggered for incapacity to work percentage.
 * It must be between 10 and 100.
 * It is not activated for CLOSED message.
 */
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import * as moment from 'moment';
import { FormValidation } from '../../shared/form-validation';
import { ApiReturnedDateFormat, StringsUtils } from 'nc-utils';

const OK = null;
const REQUIRED = { required: true };
const INVALID_INCAPACITY_PERCENTAGE = { invalidIncapacityPercentage: true };
const INVALID_VALID_FROM_VALID_TO_RANGE = { invalidValidFromValidToRange: true };
const INVALID_VALID_TO_CONTRACT_RANGE = { invalidIncapacityDatesForContractPeriodValidTo: true };
const INVALID_VALID_FROM_CONTRACT_RANGE = { invalidIncapacityDatesForContractPeriodValidFrom: true };
const INVALID_PERCENTAGE_VALUE = { percentageBetween0And100: true };
const INVALID_LAST_DAY_AFTER_FIRST_DAY = { lastDayBeforeFirstDay: true };
const COMMENT_DOCUMENT_REQUIRED = { absenceAnnexDocumentsCommentRequired: true };

/**
 * Validates if incapacity to work percentage is in the correct range.
 * Validation is triggered for NEW and prolongation type of message
 */
const incapacityPercentageRange = (): ValidatorFn => {
	return (control: AbstractControl): { [key: string]: boolean } | null => {
		const typeControl = control.get('absenceCaseType');
		const percentageControl = control.get('percentage');

		if (typeControl.value !== 'CLOSED') {
			if (StringsUtils.isNotEmpty(percentageControl.value)) {
				const numberValue = Number(percentageControl.value);
				if (!(numberValue >= 10 && numberValue <= 100)) {
					FormValidation.addError(percentageControl, INVALID_INCAPACITY_PERCENTAGE);
				}
			} else {
				FormValidation.addError(percentageControl, REQUIRED);
			}
		}

		return OK;
	};
};

/**
 * Validation for 'valid from' data.
 * Validation is active only for NEW and PROLONGATION message.
 */
const validFromRequired = (): ValidatorFn => {
	return (control: AbstractControl): { [key: string]: boolean } | null => {
		const typeControl = control.get('absenceCaseType');
		const validFromControl = control.get('validFrom');

		if (typeControl.value !== 'CLOSED' && !validFromControl.value) {
			FormValidation.addError(validFromControl, REQUIRED);
		} else {
			FormValidation.removeError(validFromControl, REQUIRED);
		}

		return OK;
	};
};

/**
 * Checks if 'valid from date' is before 'valid to date'.
 * Validation is triggered only for  NEW and PROLONGATION message.
 */
const validFromBeforeValidTo = (): ValidatorFn => {
	return (group: AbstractControl): { [key: string]: boolean } | null => {
		const typeControl = group.get('absenceCaseType');
		const validFromControl = group.get('validFrom');
		const validFrom = validFromControl.value;
		const validTo = group.get('validTo').value;

		if (typeControl.value !== 'CLOSED' && validTo && validFrom) {
			if (validTo.isBefore(validFrom)) {
				FormValidation.addError(validFromControl, INVALID_VALID_FROM_VALID_TO_RANGE);
			} else {
				FormValidation.removeError(validFromControl, INVALID_VALID_FROM_VALID_TO_RANGE);
			}
		}

		return OK;
	};
};

/**
 * Checks if number of children is filled.
 * It is active only if absence is NEW, MORE THAN 90 DAYS (PREMIUM) and insured person has children
 */
const numberOfChildrenRequired = (): ValidatorFn => {
	return (control: AbstractControl): { [key: string]: boolean } | null => {
		const typeControl = control.get('absenceCaseType');
		const hasChildrenUnder18orInEducationControl = control.get('hasChildrenUnder18orInEducationUpTo25');
		const numberOfChildrenControl = control.get('numberOfChildren');

		if (
			typeControl.value === 'NEW' &&
			hasChildrenUnder18orInEducationControl.value === 'true' &&
			(numberOfChildrenControl.value === '' || numberOfChildrenControl.value === '0')
		) {
			FormValidation.addError(numberOfChildrenControl, REQUIRED);
		}

		return OK;
	};
};

/**
 * Checks if 'valid from' is after the entry date on the contract.
 * This validation should be active for 'NEW' absence.
 */
const validFromInContractRange = (): ValidatorFn => {
	return (control: AbstractControl): { [key: string]: boolean } | null => {
		const validFromControl = control.get('validFrom');
		const caseTypeControl = control.get('absenceCaseType');
		const pkProInsuranceEntryDateControl = control.get('pkProInsuranceEntryDate');
		const pkProInsuranceExitDateControl = control.get('pkProInsuranceExitDate');
		const skip = shouldSkipContractRangeValidation(control);

		if (!skip && caseTypeControl.value === 'NEW' && validFromControl.value && pkProInsuranceEntryDateControl.value) {
			const validFromDate = moment(validFromControl.value);
			const entryDate = moment(pkProInsuranceEntryDateControl.value, ApiReturnedDateFormat);
			const exitDate = pkProInsuranceExitDateControl ? moment(pkProInsuranceExitDateControl.value, ApiReturnedDateFormat) : null;

			if (validFromDate.isBefore(entryDate) || (exitDate && validFromDate.isAfter(exitDate.add(30, 'days')))) {
				FormValidation.addError(validFromControl, INVALID_VALID_FROM_CONTRACT_RANGE);
			} else {
				FormValidation.removeError(validFromControl, INVALID_VALID_FROM_CONTRACT_RANGE);
			}
		}

		return OK;
	};
};

/**
 * Checks if 'valid to' is before the exit date of the contract.
 */
const validToInContractRange = (): ValidatorFn => {
	return (group: AbstractControl): { [key: string]: boolean } | null => {
		const validToControl = group.get('validTo');
		const caseTypeControl = group.get('absenceCaseType');
		const pkProInsuranceExitDateControl = group.get('pkProInsuranceExitDate');
		const skip = shouldSkipContractRangeValidation(group);

		if (!skip && caseTypeControl.value === 'NEW' && validToControl.value && pkProInsuranceExitDateControl.value) {
			if (moment(pkProInsuranceExitDateControl.value).isBefore(validToControl.value)) {
				FormValidation.addError(validToControl, INVALID_VALID_TO_CONTRACT_RANGE);
			} else {
				FormValidation.removeError(validToControl, INVALID_VALID_TO_CONTRACT_RANGE);
			}
		}

		return OK;
	};
};

/**
 * Employee reference is required if case type is NEW.
 */
const employeeRequired = (): ValidatorFn => {
	return (group: AbstractControl): ValidationErrors | null => {
		const employee = group.get('refEmployee');
		const caseType = group.get('absenceCaseType');
		if (StringsUtils.isEmpty(employee.value) && caseType.value === 'NEW') {
			FormValidation.addError(employee, REQUIRED);
		}

		return OK;
	};
};

/**
 * Case reference is required if case type is not NEW (PROLONGATION or CLOSED).
 */
const caseReferenceRequired = (): ValidatorFn => {
	return (group: AbstractControl): ValidationErrors | null => {
		const employee = group.get('referenceNumber');
		const caseType = group.get('absenceCaseType');

		if (StringsUtils.isEmpty(employee.value) && caseType.value !== 'NEW') {
			FormValidation.addError(employee, REQUIRED);
		} else {
			FormValidation.removeError(employee, REQUIRED);
		}

		return OK;
	};
};

/**
 * Validation for contract validity period should be skipped in case employee has EASY TEMP identifiers.
 */
const shouldSkipContractRangeValidation = (control: AbstractControl): boolean => {
	const easyTempId = control.get('employeeEasyTempId').value;

	return easyTempId != null;
};

/**
 * Filed is required is provided filed for provided @controlId has @controlValue.
 */
const requiredByValue = (controlId: string, controlValue: string): ValidatorFn => {
	return (control: AbstractControl): ValidationErrors | null => {
		const parent = control.parent;

		if (parent && parent.get(controlId)) {
			const type = parent.get(controlId).value;
			const isRequired = StringsUtils.isEmpty(control.value) && type === controlValue;
			return isRequired ? { required: true } : null;
		}

		return OK;
	};
};

/**
 * Validation for employment relationship when duration of incapacity is more than 90 days
 */
const validateEmploymentRelationship = (): ValidatorFn => {
	return (control: AbstractControl): ValidationErrors | null => {
		const employmentStartDateControl = control.get('employmentStartDate');
		const employmentEndDateControl = control.get('employmentEndDate');
		const employmentPercentageControl = control.get('employmentPercentage');
		const categoryTypeControl = control.get('categoryType');
		const absenceCaseTypeControl = control.get('absenceCaseType');
		const companyTypeControl = control.get('companyType');

		if (companyTypeControl.value === 'PERMANENT' && categoryTypeControl.value === 'PREMIUM' && absenceCaseTypeControl.value === 'NEW') {
			if (employmentStartDateControl.value == null) {
				FormValidation.addError(employmentStartDateControl, REQUIRED);
			} else if (employmentEndDateControl.value != null && employmentEndDateControl.value.isBefore(employmentStartDateControl.value)) {
				FormValidation.addError(employmentStartDateControl, INVALID_VALID_FROM_VALID_TO_RANGE);
			} else {
				FormValidation.removeError(employmentStartDateControl, REQUIRED);
				FormValidation.removeError(employmentStartDateControl, INVALID_VALID_FROM_VALID_TO_RANGE);
			}

			if (employmentPercentageControl.value === '') {
				FormValidation.addError(employmentPercentageControl, REQUIRED);
			} else if (employmentPercentageControl.value < 0 || employmentPercentageControl.value > 100) {
				FormValidation.addError(employmentPercentageControl, INVALID_PERCENTAGE_VALUE);
			} else {
				FormValidation.removeError(employmentPercentageControl, REQUIRED);
				FormValidation.removeError(employmentPercentageControl, INVALID_PERCENTAGE_VALUE);
			}
		}

		return OK;
	};
};

const validateTemporaryEmployment = (): ValidatorFn => {
	return (control: AbstractControl): ValidationErrors | null => {
		const firstDeploymentDateControl = control.get('firstDeploymentDate');
		const lastDeploymentDateControl = control.get('lastDeploymentDate');
		const categoryTypeControl = control.get('categoryType');
		const absenceCaseTypeControl = control.get('absenceCaseType');
		const companyTypeControl = control.get('companyType');

		if (companyTypeControl.value === 'TEMPORARY' && categoryTypeControl.value === 'PREMIUM' && absenceCaseTypeControl.value === 'NEW') {
			if (firstDeploymentDateControl.value == null) {
				FormValidation.addError(firstDeploymentDateControl, REQUIRED);
			} else if (lastDeploymentDateControl.value != null && lastDeploymentDateControl.value.isBefore(firstDeploymentDateControl.value)) {
				FormValidation.addError(firstDeploymentDateControl, INVALID_LAST_DAY_AFTER_FIRST_DAY);
			} else {
				FormValidation.removeError(firstDeploymentDateControl, REQUIRED);
				FormValidation.removeError(firstDeploymentDateControl, INVALID_LAST_DAY_AFTER_FIRST_DAY);
			}
		}
		return OK;
	};
};

/**
 * Checks if added comment or at least one document when do prolongation or closing.
 */
const commentOrDocumentAreRequired = (): ValidatorFn => {
	return (control: AbstractControl): ValidationErrors | null => {
		const absenceCaseType = control.get('absenceCaseType');
		const comment = control.get('comment');
		const documents = control.get('documents');
		const notNewCase = absenceCaseType.value === 'PROLONGATION' || absenceCaseType.value === 'CLOSED';

		if (notNewCase && StringsUtils.isEmpty(comment.value) && documents.value.length === 0) {
			FormValidation.addError(comment, COMMENT_DOCUMENT_REQUIRED);
			FormValidation.addError(documents, COMMENT_DOCUMENT_REQUIRED);
		} else {
			FormValidation.removeError(comment, COMMENT_DOCUMENT_REQUIRED);
			FormValidation.removeError(documents, COMMENT_DOCUMENT_REQUIRED);
		}

		return OK;
	};
};

export {
	employeeRequired,
	caseReferenceRequired,
	requiredByValue,
	validFromInContractRange,
	validToInContractRange,
	validFromRequired,
	numberOfChildrenRequired,
	validFromBeforeValidTo,
	incapacityPercentageRange,
	validateEmploymentRelationship,
	validateTemporaryEmployment,
	commentOrDocumentAreRequired,
};
