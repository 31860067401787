import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { UserSelect } from './state/action';
import { State } from '../state/app.state';

@Injectable({
	providedIn: 'root',
})
export class VerificationGuard implements CanActivate {
	constructor(private router: Router, private store: Store<State>) {}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
		return this.store.select(UserSelect.isVerified).pipe(
			map((verified) => {
				if (!verified) {
					this.router.navigateByUrl('/');
				}

				return verified;
			})
		);
	}
}
