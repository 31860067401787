import { Component } from '@angular/core';

@Component({
	templateUrl: './unavailable.component.html',
	styleUrls: ['./unavailable.component.scss'],
})
export class UnavailableComponent {
	onRetry = (): void => {
		window.location.href = '/';
	};
}
