import { Component, Input } from '@angular/core';
import { UntypedFormArray } from '@angular/forms';
import { SettingsSelect } from '../../../state/settings/action';
import { map, shareReplay, switchMap, takeUntil } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { StringsUtils } from 'nc-utils';
import { BaseComponent, Option } from 'nc-shared';
import { Store } from '@ngrx/store';
import { State } from '../../state/absence.state';
import { HttpClient } from '@angular/common/http';
import { EnvironmentLoader } from '../../../core/config/environment-loader';

@Component({
	selector: 'ea-documents',
	templateUrl: './documents.component.html',
	styleUrls: ['./documents.component.scss'],
})
export class DocumentsComponent extends BaseComponent {
	@Input() documentsFormArray: UntypedFormArray;
	@Input() id: string;
	@Input() buttonId: string;
	@Input() downloadButtonId: string;
	documentTypes$: Observable<Option[]>;

	constructor(private store: Store<State>, private http: HttpClient, private environmentLoader: EnvironmentLoader) {
		super();
		this.documentTypes$ = store.select(SettingsSelect.getLanguage).pipe(
			takeUntil(this.destroy$),
			switchMap((language) =>
				StringsUtils.isNotEmpty(language)
					? this.http.get<Array<any>>(`${this.environmentLoader.getEnvironment().API_URL}/absence-notification/document-types/${language}`)
					: of([])
			),
			map((documentTypes) => documentTypes.map((x) => ({ text: x.name, value: x.code } as Option))),
			shareReplay(1)
		);
	}
}
