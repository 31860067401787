import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { BreakpointObserver } from '@angular/cdk/layout';
import { MatSidenavContainer } from '@angular/material/sidenav';
import { BaseComponent } from 'nc-shared';

@Component({
	selector: 'ea-document-link',
	templateUrl: './document-link.component.html',
	styleUrls: ['./document-link.component.scss'],
})
export class DocumentLinkComponent extends BaseComponent {
	@Input() icon: string;
	@Input() label: string;
	@Input() type: number;
	@Input() language: string;
	@Input() sidenav: MatSidenavContainer;

	constructor(private router: Router, private breakpointObserver: BreakpointObserver) {
		super();
	}

	open(): void {
		this.router.navigate(['/document', this.language, this.type]);
		if (this.breakpointObserver.isMatched('(max-width: 600px)')) {
			this.sidenav.close();
		}
	}
}
