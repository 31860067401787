<form ncConnectForm fxLayout="column" fxFill [formGroup]="formGroup" [connectForm]="contact$ | async">
	<nc-main-content>
		<div fxLayout="column" fxLayoutAlign="center">
			<nc-form-content>
				<nc-form-title label="contactPerson"></nc-form-title>
				<div class="toggle-button-group-parent">
					<div class="in-line right-10-space">
						<nc-toggle-buttons [formGroup]="formGroup" label="title" [options]="titles" formControlName="title"> </nc-toggle-buttons>
					</div>
					<div class="in-line">
						<nc-toggle-buttons [formGroup]="formGroup" label="employeeFunction" [options]="functions" formControlName="function">
						</nc-toggle-buttons>
					</div>
				</div>
				<nc-input
					[id]="'first-name'"
					ncAutofocus
					[shouldAutofocus]="true"
					[formGroup]="formGroup"
					formControlName="firstName"
					label="firstName"
				></nc-input>
				<nc-input [id]="'last-name'" [formGroup]="formGroup" formControlName="lastName" label="lastName"></nc-input>
				<nc-input [id]="'email'" [formGroup]="formGroup" formControlName="email" label="email"></nc-input>
				<nc-input
					[id]="'phone'"
					[formGroup]="formGroup"
					label="phone"
					isPhoneNumber="true"
					formControlName="phone"
					tooltip="phoneNumberSMSTooltip"
					maxLength="15"
					(keyup)="onPhoneCheck($event)"
				>
				</nc-input>
				<div class="toggle-button-group-parent">
					<nc-toggle-buttons
						id="isPhoneForFurtherInquiries"
						[formGroup]="formGroup"
						formControlName="isPhoneForQuestions"
						label="isPhoneNumberFurtherInquiries"
						[options]="yesNoOptions"
					>
					</nc-toggle-buttons>
				</div>
				<nc-input
					*ngIf="formGroup.get('isPhoneForQuestions').value == 'no'"
					id="phoneForFurtherInquiries"
					[formGroup]="formGroup"
					formControlName="phoneForQuestions"
					isPhoneNumber="true"
					label="phoneNumberFurtherInquiries"
					maxLength="15"
					(keyup)="onPhoneForQuestionsCheck($event)"
				>
				</nc-input>
			</nc-form-content>
		</div>
	</nc-main-content>
	<ea-footer
		[formGroup]="formGroup"
		message="fieldsAreMandatory"
		[onNext]="onSubmit"
		onNextLabel="next"
		[onCancel]="onCancel"
		onCancelLabel="cancel"
		[onNextDisabled]="formGroup.invalid"
	>
	</ea-footer>
</form>
