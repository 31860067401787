import { Component, Input } from '@angular/core';
import { CaseReferenceDetails } from '../../model/case-reference-details/case-reference-details';
import { BehaviorSubject, Observable } from 'rxjs';
import { ColumnBuilder, DatatableColumn, TableData } from 'nc-datatable';
import { BreakpointObserver } from '@angular/cdk/layout';
import { FilterConfig } from 'nc-datatable/lib/model/filter/filter-config';

@Component({
	selector: 'ea-periods-of-incapacity-to-work',
	templateUrl: './periods-of-incapacity-to-work.component.html',
	styleUrls: ['./periods-of-incapacity-to-work.component.scss'],
})
export class PeriodsOfIncapacityToWorkComponent {
	@Input() caseDetails: CaseReferenceDetails;
	@Input() incapacities$: Observable<TableData>;

	columns$: BehaviorSubject<Array<DatatableColumn>> = new BehaviorSubject<Array<DatatableColumn>>([]);
	filterConfig: FilterConfig;

	constructor(breakpointObserver: BreakpointObserver) {
		breakpointObserver.observe(['(min-width: 1450px)', '(max-width: 900px)']).subscribe((result) => {
			console.log('Matches: ' + result.matches);
			const columns = result.matches ? this.initColumns() : this.initReducedColumns();
			this.columns$.next(columns);
		});

		this.filterConfig = {
			showFilter: false,
			showGlobalFilter: true,
			columnFiltersFormGroup: null,
			filterValues: null,
			globalFilterFormGroup: null,
		};
	}

	initColumns = (): Array<DatatableColumn> => {
		return [
			ColumnBuilder.createHidden('id'),
			ColumnBuilder.create('validFrom', 'validFrom'),
			ColumnBuilder.create('validTo', 'validTo'),
			ColumnBuilder.create('percentage', 'percentage'),
			ColumnBuilder.create('comment', 'comment'),
			ColumnBuilder.create('createdBy', 'createdBy'),
			ColumnBuilder.create('createdOn', 'createdOn'),
		];
	};

	initReducedColumns = (): Array<DatatableColumn> => {
		return [
			ColumnBuilder.createHidden('id'),
			ColumnBuilder.create('validFrom', 'validFrom'),
			ColumnBuilder.create('validTo', 'validTo'),
			ColumnBuilder.create('percentage', 'percentage'),
		];
	};

	getContainerClass = (): string => {
		return this.caseDetails?.incapacities ? '' : 'hidden';
	};
}
