class QueryResponse {
	page: number;
	totalCount: number;
	records: { id: string; text: string }[];
}

const toOption = (record) => {
	return {
		value: record.id,
		text: record.text,
	};
};

export { QueryResponse, toOption };
