import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

const PHONE_FORMAT_ERROR = { invalidFormatPhone: true };

const PHONE_NUMBER = /^\+[0-9]\d*$/;

const validatePhone = (): ValidatorFn => {
	return (control: AbstractControl): ValidationErrors | null => {
		const value = control.value;
		if (!PHONE_NUMBER.test(value)) {
			return PHONE_FORMAT_ERROR;
		}
	};
};

export { validatePhone };
