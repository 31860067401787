import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { Absence } from '../model/absence';
import { SaveAbsenceRequest } from '../model/request/save-absence-request';
import { SaveAnnexRequest } from '../model/request/save-annex-request';
import { ContactDetails } from '../../user/model/contact-details';
import { IncapacityToWork } from '../model/incapacity-to-work';
import { dateToString, FormUtils } from 'nc-utils';

/**
 * Encapsulation of request creation.
 * It creates request
 */
@Injectable({
	providedIn: 'root',
})
export class RequestModelFactory {
	constructor(@Inject(LOCALE_ID) private local: string) {}

	create(absence, contactPerson: ContactDetails, language: string, isForceNew: boolean): FormData {
		return FormUtils.objectToFormData({
			caseType: absence.absenceCaseType,
			documents: absence.documents,
			model: {
				...absence,
				forcedNew: isForceNew,
				documents: [],
				contactPerson,
				language,
				firstDeploymentDate: dateToString(absence.firstDeploymentDate),
				lastDeploymentDate: dateToString(absence.lastDeploymentDate),
				employmentStartDate: dateToString(absence.employmentStartDate),
				employmentEndDate: dateToString(absence.employmentEndDate),
				pregnancyDueDate: dateToString(absence.pregnancyDueDate),
				incapacities: [
					{
						percentage: absence.percentage ? absence.percentage : 0,
						validFrom: absence.validFrom ? dateToString(absence.validFrom) : null,
						validTo: absence.validTo ? dateToString(absence.validTo) : null,
						comment: absence.comment,
					} as IncapacityToWork,
				],
			},
		} as SaveAbsenceRequest);
	}

	createAnnex(absence: Absence, contact: ContactDetails, language: string): FormData {
		return FormUtils.objectToFormData({
			caseType: absence.absenceCaseType,
			documents: absence.documents,
			model: {
				language,
				absenceCode: absence.referenceNumber,
				type: absence.absenceCaseType,
				validFrom: absence.validFrom ? dateToString(absence.validFrom) : null,
				validTo: absence.validTo ? dateToString(absence.validTo) : null,
				incapacityToWork: absence.percentage ? absence.percentage : 0,
				comment: absence.comment,
				contactPerson: contact,
			},
		} as SaveAnnexRequest);
	}
}
