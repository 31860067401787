import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
	selector: 'ea-footer',
	templateUrl: './footer.component.html',
	styleUrls: ['./footer.component.css'],
})
export class FooterComponent {
	@Input() message: string;
	@Input() formGroup: UntypedFormGroup;

	@Input() onNext: () => void;
	@Input() onNextLabel: string;
	@Input() onNextDisabled: boolean = false;

	@Input() onCancel: () => void;
	@Input() onCancelLabel: string;
}
