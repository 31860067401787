import { createReducer, on } from '@ngrx/store';
import { SETTINGS_INITIAL_STATE, SettingsState } from './settings.state';
import { SettingsAction } from './action';

export const settingsReducer = createReducer<SettingsState>(
	SETTINGS_INITIAL_STATE,
	on(SettingsAction.setLanguage, (state, action): SettingsState => {
		return {
			...state,
			language: action.language,
		};
	}),
	on(SettingsAction.setReady, (state, action): SettingsState => {
		return {
			...state,
			ready: action.ready,
		};
	})
);
