import { Component, Input } from '@angular/core';

@Component({
	selector: 'ea-logo',
	templateUrl: './logo.component.html',
	styleUrls: ['./logo.component.scss'],
})
export class LogoComponent {
	@Input() url: string;
	@Input() width: number;
	@Input() height: number;
}
