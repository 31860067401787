/**
 * Global state for the application.
 */
export interface SettingsState {
	/**
	 * Selected languages of the client.
	 */
	language: string;
	/**
	 * Flag which indicates that application is loaded and ready to use.
	 */
	ready: boolean;
}

export const SETTINGS_INITIAL_STATE = {
	language: 'de',
	ready: false,
};
