<div class="contact-card">
	<mat-card>
		<mat-card-header>
			<mat-card-title>{{ 'anyQuestions' | translate }}</mat-card-title>
		</mat-card-header>
		<mat-card-content>
			<mat-list role="list">
				<mat-list-item role="listitem">
					{{ 'answerQuestion' | translate }}
				</mat-list-item>
				<mat-list-item role="listitem">
					<mat-icon>phone</mat-icon>
					<a class="card-link" href="tel:{{ 'eCareTelephone' | translate }}">{{ 'eCareTelephone' | translate }}</a>
				</mat-list-item>
				<mat-list-item role="listitem">
					<mat-icon>alternate_email</mat-icon>
					<a class="card-link" href="mailto:{{ 'tellcoEmail' | translate }}">{{ 'tellcoEmail' | translate }}</a>
				</mat-list-item>
			</mat-list>
		</mat-card-content>
	</mat-card>
</div>
