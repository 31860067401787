import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ErrorHandlerService } from '../../shared/service/error-handler.service';
import { ActionResponse } from 'nc-utils';
import { EnvironmentLoader } from '../../core/config/environment-loader';
import { HrApplicationAccessRequest } from '../model/hr-application-access-request';

@Injectable({
	providedIn: 'root',
})
export class HrApplicationAccessRequestService {
	constructor(private http: HttpClient, private errorHandler: ErrorHandlerService, private environmentLoader: EnvironmentLoader) {}

	private readonly API_URL = this.environmentLoader.getEnvironment().API_URL;

	sendEmail(request: HrApplicationAccessRequest): Observable<ActionResponse> {
		const url = `${this.API_URL}/request-form/sendEmail`;
		return this.http.post<ActionResponse>(url, request);
	}
}
