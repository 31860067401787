import { ValidatorErrorCode } from './validation-error-code';
import { ValidationError } from 'nc-shared';

export const ErrorMessageMap: Record<string, ValidationError> = {
	[ValidatorErrorCode.required]: {
		label: 'mandatory.field',
		value: (params) => null,
	},
	[ValidatorErrorCode.invalidValidFromValidToRange]: {
		label: 'invalidValue.absence.validFrom',
		value: (params) => null,
	},
	[ValidatorErrorCode.invalidIncapacityDatesForContractPeriodValidFrom]: {
		label: 'invalidIncapacityDatesForContractPeriod.absence.validFrom',
		value: (params) => null,
	},
	[ValidatorErrorCode.invalidIncapacityDatesForContractPeriodValidTo]: {
		label: 'invalidIncapacityDatesForContractPeriod.absence.validTo',
		value: (params) => null,
	},
	[ValidatorErrorCode.minlength]: {
		label: 'ERROR_form_minLength',
		value: (params) => ({ minLength: params?.requiredLength }),
	},
	[ValidatorErrorCode.maxlength]: {
		label: 'ERROR_form_maxLength',
		value: (params) => ({ maxLength: params?.requiredLength }),
	},
	[ValidatorErrorCode.email]: {
		label: 'invalidFormat',
		value: () => null,
	},
	[ValidatorErrorCode.customEmail]: {
		label: 'invalidFormat',
		value: () => null,
	},
	[ValidatorErrorCode.invalidFormatPhone]: {
		label: 'invalidFormatPhone',
		value: () => null,
	},
	[ValidatorErrorCode.invalidIncapacityPercentage]: {
		label: 'invalidValue.absence.percentage',
		value: () => null,
	},
	[ValidatorErrorCode.invalidPassword]: {
		label: 'ERROR_form_password',
		value: () => null,
	},
	[ValidatorErrorCode.numberValueLength]: {
		label: 'ERROR_form_numberValueLength',
		value: (params) => ({ expectedLength: params?.requiredLength }),
	},
	[ValidatorErrorCode.pattern]: {
		label: 'ERROR_form_pattern',
		value: (params) => ({ data: params?.requiredPattern }),
	},
	[ValidatorErrorCode.mismatch]: {
		label: 'ERROR_form_mismatch',
		value: (params) => ({ data: params?.displayName }),
	},
	[ValidatorErrorCode.matDatepickerParse]: {
		label: 'ERROR_form_wrongDate',
		value: (params) => null,
	},
	[ValidatorErrorCode.maxFileSize]: {
		label: 'maxFileSizeExceeded',
		value: (params) => ({ maxSizeInMb: params }),
	},
	[ValidatorErrorCode.requiredFile]: {
		label: 'ERROR_file_requiredFile',
		value: (params) => null,
	},
	[ValidatorErrorCode.atLeastOne]: {
		label: 'ERROR_form_atLeastOne',
		value: (params) => null,
	},
	[ValidatorErrorCode.invalidContractNumberOrName]: {
		label: 'report.home.invalidCompanyName.or.invalidContractNo',
		value: (params) => null,
	},
	[ValidatorErrorCode.totalMaxFilesSize]: {
		label: 'totalMaxFilesSizeExceeded',
		value: (params) => ({ totalMaxFilesSize: params }),
	},
	[ValidatorErrorCode.uniqueName]: {
		label: 'nameIsNotUnique',
		value: (params) => null,
	},
	[ValidatorErrorCode.absenceAnnexDocumentsCommentRequired]: {
		label: 'mandatory.absence.annexContent.comment',
		value: () => null,
	},
	[ValidatorErrorCode.public_form_wrong_phone_number_format]: {
		label: 'public_form_wrong_phone_number_format',
		value: () => null,
	},
};
