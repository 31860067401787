<div
	fxLayout="row"
	fxLayoutGap="10px"
	fxLayoutGap.lt-md="20px"
	fxLayoutGap.lt-sm="30px"
	fxLayout.lt-md="column"
	[formGroup]="formGroup"
	class="section"
>
	<div fxFlex="50" fxLayout="column" fxLayoutGap="10px">
		<nc-quick-search
			[textInputId]="'insured-person'"
			[resetButtonId]="'insured-person-reset'"
			[undoButtonId]="'insured-person-undo'"
			ncAutofocus
			[shouldAutofocus]="true"
			formControlName="referenceNumber"
			label="insuredPerson"
			[formGroup]="formGroup"
			[fetchAll]="filterItems"
			[fetchSingle]="fetchCaseOption"
			placeholder="insuredPersonPleaceholder"
			[displayOptionFunc]="displayFunc"
		>
			<ng-template let-item>
				<span class="mat-option-title">{{ item.text | split : ';' : 0 }}</span
				><br />
				<span class="mat-option-footer">{{ item.text | split : ';' : 1 }}</span>
			</ng-template>
		</nc-quick-search>
		<div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="10px" class="flex-wrap">
			<nc-datepicker [id]="'valid-from'" [formGroup]="formGroup" formControlName="validFrom" label="validFrom"></nc-datepicker>
			<nc-datepicker [id]="'valid-to'" [formGroup]="formGroup" formControlName="validTo" label="validTo"></nc-datepicker>
		</div>
		<nc-input [id]="'percentage'" [formGroup]="formGroup" formControlName="percentage" label="percentage" [mask]="{ mask: '000' }"></nc-input>
		<nc-textarea [id]="'comment'" [formGroup]="formGroup" formControlName="comment" label="comment" maxLength="300"></nc-textarea>
	</div>
	<ea-periods-of-incapacity-to-work fxFlex="50" [caseDetails]="caseDetails$ | async" [incapacities$]="incapacities$">
	</ea-periods-of-incapacity-to-work>
</div>
