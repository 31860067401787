export enum ValidatorErrorCode {
	required = 'required',
	maxlength = 'maxlength',
	minlength = 'minlength',
	email = 'email',
	pattern = 'pattern',
	invalidPassword = 'invalidPassword',
	numberValueLength = 'numberValueLength',
	mismatch = 'mismatch',
	matDatepickerParse = 'matDatepickerParse',
	customEmail = 'customEmail',
	maxFileSize = 'maxFileSize',
	requiredFile = 'requiredFile',
	atLeastOne = 'atLeastOne',
	atLeastOneSelected = 'atLeastOneSelected',
	invalidContractNumberOrName = 'invalidContractNumberOrName',
	invalidFormatPhone = 'invalidFormatPhone',
	invalidIncapacityPercentage = 'invalidIncapacityPercentage',
	invalidValidFromValidToRange = 'invalidValidFromValidToRange',
	invalidIncapacityDatesForContractPeriodValidFrom = 'invalidIncapacityDatesForContractPeriodValidFrom',
	invalidIncapacityDatesForContractPeriodValidTo = 'invalidIncapacityDatesForContractPeriodValidTo',
	uniqueName = 'uniqueName',
	totalMaxFilesSize = 'totalMaxFilesSize',
	absenceAnnexDocumentsCommentRequired = 'absenceAnnexDocumentsCommentRequired',
	public_form_wrong_phone_number_format = 'public_form_wrong_phone_number_format',
}
