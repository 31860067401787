import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { AbsenceSelectAction } from '../../../state/action';
import { Employee } from '../../../model/employee';
import { State } from '../../../state/absence.state';
import { Store } from '@ngrx/store';

@Component({
	templateUrl: './insured-person-dialog.component.html',
	styleUrls: ['./insured-person-dialog.component.scss'],
})
export class InsuredPersonDialogComponent implements OnInit {
	employee$: Observable<Employee>;

	constructor(private store: Store<State>) {}

	ngOnInit(): void {
		this.employee$ = this.store.select(AbsenceSelectAction.getEmployee);
	}
}
