import { SharedModule } from '../shared/shared.module';
import { StoreModule } from '@ngrx/store';
import { NgModule } from '@angular/core';
import { SignInComponent } from './component/sign-in/sign-in.component';
import { USER_FEATURE_STATE_NAME } from './state/action/user.select';
import { EffectsModule } from '@ngrx/effects';
import { UserEffect } from './state/user.effect';
import { userReducer } from './state/user.reducer';
import { RouterModule } from '@angular/router';
import { ContactInformationComponent } from './component/contact-information/contact-information.component';
import { SmsVerificationComponent } from './component/sms-verification/sms-verification.component';
import { NcMainContentModule } from 'nc-main-content';
import { NcFormModule } from 'nc-form';
import { NcUtilsModule } from 'nc-utils';
import { HrAppAccessRequestFormComponent } from './component/hr-app-access-request-form/hr-app-access-request-form.component';
import { RequestConfirmationDialogComponent } from './component/dialog/request-confirmation-dialog.component';
import { BannerComponent } from './component/sign-in/banner/banner.component';

@NgModule({
	imports: [
		SharedModule,
		RouterModule.forChild([
			{ path: 'contact', component: ContactInformationComponent, data: { title: 'reportContactPage.title' } },
			{ path: 'sms', component: SmsVerificationComponent, data: { title: 'reportSMSVerificationFormPage.title' } },
		]),
		EffectsModule.forFeature([UserEffect]),
		StoreModule.forFeature(USER_FEATURE_STATE_NAME, userReducer),
		NcMainContentModule,
		NcFormModule,
		NcUtilsModule,
	],
	declarations: [
		SignInComponent,
		SmsVerificationComponent,
		ContactInformationComponent,
		HrAppAccessRequestFormComponent,
		RequestConfirmationDialogComponent,
		BannerComponent,
	],
	exports: [BannerComponent],
})
export class UserModule {}
