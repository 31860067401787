import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '../../../state/absence.state';
import { AbsenceService } from '../../../service/absence.service';
import { BaseCaseComponent } from '../base-case.component';

@Component({
	selector: 'ea-prolongation-message',
	templateUrl: './prolongation-message.component.html',
	styleUrls: ['./prolongation-message.component.scss'],
})
export class ProlongationMessageComponent extends BaseCaseComponent {
	constructor(protected store: Store<State>, protected absenceService: AbsenceService) {
		super(store, absenceService);
	}
}
