/**
 * Class which holds methods for string checks and manipulation.
 */
import { AbstractControl } from '@angular/forms';

export class FormValidation {
	/**
	 * Checks if string is not empty.
	 * @param control form control to add error to
	 * @param error which should be added to form control errors object
	 */
	static addError(control: AbstractControl, error: { [key: string]: any }): void {
		control.setErrors({ ...control.errors, ...error });
	}

	/**
	 * Remove specific error from form control
	 * @param control control to remove error from
	 * @param error which should be removed form control errors object
	 */
	static removeError(control: AbstractControl, error: { [key: string]: any }): void {
		if (control.errors) {
			delete control.errors[Object.keys(error)[0]];

			if (!Object.keys(control.errors).length) {
				control.setErrors(null);
			}
		}
	}
}
