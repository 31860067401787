import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { NcSharedModule } from 'nc-shared';
import { FooterComponent } from './component/footer/footer.component';
import { NcInputModule } from 'nc-input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatButtonModule } from '@angular/material/button';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NcButtonModule } from 'nc-button';
import { NcCheckboxModule } from 'nc-checkbox';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { DateAdapter, MAT_DATE_FORMATS, MatNativeDateModule } from '@angular/material/core';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatCardModule } from '@angular/material/card';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { UnavailableComponent } from './component/error/unavailable/unavailable.component';
import { NcSplashScreenModule } from 'nc-splash-screen';
import { NcLanguagePickerModule } from 'nc-language-picker';
import { LogoComponent } from './component/navigation/logo/logo.component';
import { MatDialogModule } from '@angular/material/dialog';
import { RouterModule } from '@angular/router';
import { DocumentLinkComponent } from './component/navigation/document-link/document-link.component';
import { ContactCardComponent } from './component/navigation/contact-card/contact-card.component';
import { NcDatatableModule } from 'nc-datatable';
import { NcSelectModule } from 'nc-select';
import { NcSubmitButtonModule } from 'nc-submit-button';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { NcToggleButtonsModule } from 'nc-toggle-buttons';
import { MatDividerModule } from '@angular/material/divider';
import { NcTextareaModule } from 'nc-textarea';
import { MatTableModule } from '@angular/material/table';
import { NcDatepickerModule } from 'nc-datepicker';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { NcQuickSearchModule } from 'nc-quick-search';
import { UnauthorizedAccessDialogComponent } from './component/dialog/unauthorized-access-dialog/unauthorized-access-dialog.component';
import { NcDecisionDialogModule } from 'nc-decision-dialog';
import { NcConfirmationDialogModule } from 'nc-confirmation-dialog';
import { NotFoundComponent } from './component/error/not-found/not-found.component';
import { Store } from '@ngrx/store';
import { State } from '../state/app.state';
import { SettingsSelect } from '../state/settings/action';
import { NcInformationDialogModule } from 'nc-information-dialog';
import { VersionComponent } from './component/version/version.component';
import { NcLabelModule } from 'nc-label';
import { NcMainContentModule } from 'nc-main-content';
import { NcFormModule } from 'nc-form';
import { NcDocumentsModule } from 'nc-documents';
import { DateFormats, NcUtilsModule } from 'nc-utils';

@NgModule({
	imports: [
		NcInputModule,
		CommonModule,
		NcSharedModule,
		TranslateModule,
		MatIconModule,
		MatButtonModule,
		MatMenuModule,
		MatListModule,
		MatProgressBarModule,
		FlexLayoutModule,
		NcButtonModule,
		NcCheckboxModule,
		MatCardModule,
		MatDialogModule,
		NgxExtendedPdfViewerModule,
		ScrollingModule,
		RouterModule,
		NcSubmitButtonModule,
		MatDialogModule,
		MatTableModule,
		ReactiveFormsModule,
		NcDatatableModule,
		NcSelectModule,
		MatMomentDateModule,
		FormsModule,
		MatInputModule,
		NcQuickSearchModule,
		NcDecisionDialogModule,
		NcInformationDialogModule,
		NcConfirmationDialogModule,

		NcLabelModule,
		NcMainContentModule,
		NcFormModule,
		NcUtilsModule,
		NcDocumentsModule,
	],
	exports: [
		FormsModule,
		CommonModule,
		TranslateModule,
		ReactiveFormsModule,
		ContactCardComponent,
		DocumentLinkComponent,
		LogoComponent,
		FooterComponent,
		NcSubmitButtonModule,

		NcSharedModule,
		NcInputModule,
		NcButtonModule,
		NcCheckboxModule,
		NcSplashScreenModule,
		NcLanguagePickerModule,
		NcSelectModule,
		NcToggleButtonsModule,
		NcTextareaModule,
		NcDatepickerModule,
		NcDecisionDialogModule,
		NcConfirmationDialogModule,
		NcLabelModule,
		NcMainContentModule,
		NcFormModule,
		NcUtilsModule,
		NcDocumentsModule,

		FlexLayoutModule,

		MatButtonModule,
		MatFormFieldModule,
		MatProgressBarModule,
		MatToolbarModule,
		MatIconModule,
		MatSidenavModule,
		MatListModule,
		MatMenuModule,
		MatCardModule,
		MatExpansionModule,
		MatDatepickerModule,
		MatInputModule,
		MatDialogModule,
		MatDividerModule,
		MatTableModule,

		NgxExtendedPdfViewerModule,

		MatNativeDateModule,
		NcDatatableModule,
		NcQuickSearchModule,
		NcInformationDialogModule,
		UnauthorizedAccessDialogComponent,
		VersionComponent,
	],
	declarations: [
		ContactCardComponent,
		DocumentLinkComponent,
		LogoComponent,
		FooterComponent,
		UnavailableComponent,
		NotFoundComponent,
		UnauthorizedAccessDialogComponent,
		VersionComponent,
	],
	providers: [{ provide: MAT_DATE_FORMATS, useValue: DateFormats }],
})
export class SharedModule {
	constructor(private store: Store<State>, private dateAdapter: DateAdapter<any>) {
		store.select(SettingsSelect.getLanguage).subscribe((language) => {
			this.dateAdapter.setLocale(language);
		});
	}
}
