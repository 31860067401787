import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { BaseComponent, Option } from 'nc-shared';
import { EmploymentRegistrationEnumeration } from '../../../../model/enumerations/employment-registration.enumeration';
import { EmploymentSubjectEnumeration } from '../../../../model/enumerations/employment-subject.enumeration';
import { FlagEnumeration } from '../../../../model/enumerations/flag.enumeration';
import { Company } from '../../../../model/company';
import { EnumUtils } from 'nc-utils';
import { AbsenceSelectAction } from '../../../../state/action';
import { takeUntil } from 'rxjs/operators';
import { Store } from '@ngrx/store';

@Component({
	selector: 'ea-premium-details',
	templateUrl: './premium-details.component.html',
	styleUrls: ['./premium-details.component.scss'],
})
export class PremiumDetailsComponent extends BaseComponent implements OnInit, OnDestroy {
	@Input() formGroup: UntypedFormGroup;
	@Input() company: Company;

	FlagEnumeration = FlagEnumeration;
	flags: Option[];
	employmentSubject: Option[];
	employmentRegistration: Option[];

	constructor(private store: Store, private changeDetectorRef: ChangeDetectorRef) {
		super();
		this.flags = EnumUtils.toItems(FlagEnumeration);
		this.employmentSubject = EnumUtils.toItems(EmploymentSubjectEnumeration);
		this.employmentRegistration = EnumUtils.toItems(EmploymentRegistrationEnumeration);
	}

	ngOnInit(): void {
		const numberOfChildren = this.formGroup.get('numberOfChildren');
		const hasChildren = this.formGroup.get('hasChildrenUnder18orInEducationUpTo25');
		const absenceType = this.formGroup.get('absenceType');
		const hasAccidentInsurer = this.formGroup.get('hasAccidentInsurance');
		const accidentCompany = this.formGroup.get('accidentInsuranceCompany');
		const accidentClaimNumber = this.formGroup.get('accidentInsuranceSinisterReference');

		const hasSicknessInsurer = this.formGroup.get('hasDailySicknessBenefitsInsurance');
		const sicknessCompany = this.formGroup.get('dailySicknessBenefitsInsuranceCompany');
		const sicknessClaimNumber = this.formGroup.get('dailySicknessBenefitsInsuranceSinisterReference');

		this.store
			.select(AbsenceSelectAction.getErrors)
			.pipe(takeUntil(this.destroy$))
			.subscribe((errors) => {
				if (errors) {
					Object.keys(errors).forEach((key) => {
						const control = this.formGroup.get(key);
						if (control) {
							control.setErrors({ [errors[key].code]: null });
							control.markAsTouched();
							this.changeDetectorRef.markForCheck();
						}
					});
				}
			});

		hasChildren.valueChanges.subscribe((answer) => {
			if (answer === 'no') {
				numberOfChildren.patchValue('0');
			}
		});

		hasAccidentInsurer.valueChanges.subscribe((answer) => {
			if (answer === 'no') {
				accidentCompany.patchValue('');
				accidentClaimNumber.patchValue('');
			}
		});

		hasSicknessInsurer.valueChanges.subscribe((answer) => {
			if (answer === 'no') {
				sicknessCompany.patchValue('');
				sicknessClaimNumber.patchValue('');
			} else {
				if (this.company.fullDetails.isSwicaInsured) {
					sicknessCompany.patchValue('SWICA');
				}
			}
		});

		absenceType.valueChanges.subscribe(() => {
			hasAccidentInsurer.patchValue('no');
			hasSicknessInsurer.patchValue('no');
		});
	}

	ngOnDestroy(): void {
		this.formGroup.patchValue({
			hasDailySicknessBenefitsInsurance: 'no',
			dailySicknessBenefitsInsuranceCompany: '',
			dailySicknessBenefitsInsuranceSinisterReference: '',

			hasAccidentInsurance: 'no',
			accidentInsuranceCompany: '',
			accidentInsuranceSinisterReference: '',

			employmentStartDate: null,
			employmentEndDate: null,
			employmentPercentage: '',
			employmentAnnualSalary: '',

			firstDeploymentDate: null,
			firstDeploymentRegistration: 'REGISTRATION_FROM_1ST_DAY',
			firstDeploymentSubject: 'SUBJECT_TO_DUTY_OF_MAINTENANCE',
			lastDeploymentDate: null,
		});
	}
}
