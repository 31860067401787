import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { State } from '../../../state/app.state';
import { Store } from '@ngrx/store';
import { UserPageAction } from '../../state/action';
import { CanDeactivateFormComponent } from 'nc-shared';

@Component({
	templateUrl: './sms-verification.component.html',
	styleUrls: ['./sms-verification.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SmsVerificationComponent extends CanDeactivateFormComponent implements OnInit {
	constructor(private router: Router, private store: Store<State>, private formBuilder: UntypedFormBuilder) {
		super();
	}

	ngOnInit(): void {
		this.formGroup = this.formBuilder.group({ code: ['', Validators.required] });
	}

	onResend = (): void => {
		this.store.dispatch(UserPageAction.resend());
		this.formGroup.patchValue({ code: '' });
		this.formGroup.reset();
	};

	onBack = (): void => {
		this.router.navigateByUrl('/verification/contact');
	};

	dispatchSubmitAction = (): void => {
		const code = this.formGroup.getRawValue();
		this.store.dispatch(UserPageAction.verify(code));
	};
}
