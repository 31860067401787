import { UserAccess } from '../model/user-access';
import { UserCredentials } from '../model/user-credentials';
import { ContactDetails } from '../model/contact-details';

export interface UserState {
	access: UserAccess;
	contact: ContactDetails;
	credentials: UserCredentials;
	companyId: string;
	/**
	 * Flag which indicates if client has passed verification (verification module).
	 */
	verified: boolean;
}

export const USER_INITIAL_STATE: UserState = {
	credentials: {
		contractNumber: '',
		first3Letters: '',
		remember: false,
	},
	access: {
		token: '',
		granted: false,
		message: '',
	},
	contact: {
		email: '',
		lastName: '',
		firstName: '',
		phone: '',
		title: 'MALE',
		function: 'BROKER',
	},
	companyId: '',
	verified: false,
};
