import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { UserSelect } from './state/action';
import { map } from 'rxjs/operators';
import { State } from '../state/app.state';

@Injectable({
	providedIn: 'root',
})
export class SignInGuard implements CanActivate {
	constructor(private router: Router, private store: Store<State>) {}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
		return this.store.select(UserSelect.hasAccess).pipe(
			map((result) => {
				if (!result) {
					this.router.navigateByUrl('/');
				}
				return result;
			})
		);
	}
}
