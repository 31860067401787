import { FormGroup } from '@angular/forms';

export class PhoneUtils {
	private static signPlus = '+';

	public static formatPhoneNumber(input: HTMLInputElement, formGroup: FormGroup): void {
		const inputValue = input.value;

		if (!inputValue.startsWith(this.signPlus)) {
			formGroup.controls.phone.patchValue(this.signPlus + inputValue);
		}
	}
}
