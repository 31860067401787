import { Injectable } from '@angular/core';
import { State } from '../../state/app.state';
import { Store } from '@ngrx/store';
import { SettingsAction } from '../../state/settings/action';

/**
 * Service which is responsible for setting the correct status of the application.
 * Flag "ready" is read by application guard.
 */
@Injectable({
	providedIn: 'root',
})
export class ReadyService {
	constructor(private store: Store<State>) {}

	/**
	 * Sets ready status to true.
	 * This means that application si ready to be used.
	 */
	ready(): void {
		this.store.dispatch(SettingsAction.setReady({ ready: true }));
	}

	/**
	 * Sets ready status to false.
	 * This means that application is not ready to be used.
	 */
	notReady(): void {
		this.store.dispatch(SettingsAction.setReady({ ready: false }));
	}
}
