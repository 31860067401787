import { Component, Input } from '@angular/core';
import { Absence } from '../../model/absence';

@Component({
	selector: 'ea-insurance-period',
	templateUrl: './insurance-period.component.html',
	styleUrls: ['./insurance-period.component.scss'],
})
export class InsurancePeriodComponent {
	@Input() absence: Absence;
}
