import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { UserAccess } from '../../model/user-access';
import { UserPageAction, UserSelect } from '../../state/action';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { filter, takeUntil } from 'rxjs/operators';
import { UserCredentials } from '../../model/user-credentials';
import { CanDeactivateFormComponent } from 'nc-shared';
import { State } from '../../../state/app.state';

@Component({
	templateUrl: './sign-in.component.html',
	styleUrls: ['./sign-in.component.scss'],
})
export class SignInComponent extends CanDeactivateFormComponent implements OnInit {
	access$: Observable<UserAccess>;
	credentials$: Observable<UserCredentials>;

	constructor(private store: Store<State>, private formBuilder: UntypedFormBuilder) {
		super();
	}

	ngOnInit(): void {
		this.store.dispatch(UserPageAction.initialize());

		this.access$ = this.store.select(UserSelect.getAccess);
		this.credentials$ = this.store.select(UserSelect.getCredentials);

		this.formGroup = this.formBuilder.group({
			contractNumber: ['', [Validators.required]],
			first3Letters: ['', [Validators.required]],
			remember: [true],
		});

		this.access$
			.pipe(
				takeUntil(this.destroy$),
				filter((result) => !result.granted)
			)
			.subscribe((x) => this.formGroup.get('first3Letters').setErrors({ [x.message]: null }));
	}

	dispatchSubmitAction = (): void => {
		const data = this.formGroup.getRawValue();
		this.store.dispatch(UserPageAction.signInSubmit(data));
	};

	openForm = (title: string): void => {
		this.store.dispatch(UserPageAction.openRequestFormDialog({ title }));
	};
}
