<div [formGroup]="formGroup" class="card-section">
	<span class="card-section-title">{{ 'premiumAbsence' | translate }}</span>
	<div fxLayout="column" class="sub-section-no-border" fxLayoutGap="5px">
		<div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column">
			<div class="toggle-button-side-label">{{ 'hasChildrenUnder18' | translate }}</div>
			<nc-toggle-buttons [formGroup]="formGroup" [options]="flags" formControlName="hasChildrenUnder18orInEducationUpTo25"> </nc-toggle-buttons>
		</div>
		<nc-input
			[id]="'premium-number-of-children'"
			*ngIf="formGroup.controls.hasChildrenUnder18orInEducationUpTo25.value === FlagEnumeration.yes"
			[mask]="{ mask: '00' }"
			[formGroup]="formGroup"
			formControlName="numberOfChildren"
			label="howMany"
		>
		</nc-input>
	</div>
	<div class="sub-section" fxLayout="column">
		<span class="sub-section-title">{{ 'employmentRelationship' | translate }}</span>

		<div fxLayout="column" *ngIf="!company.temporaryContract">
			<div fxLayout="row" fxLayoutGap="10px" class="flex-wrap">
				<nc-datepicker [id]="'premium-employment-start-date'" [formGroup]="formGroup" formControlName="employmentStartDate" label="beginning">
				</nc-datepicker>
				<nc-datepicker [id]="'premium-employment-end-date'" [formGroup]="formGroup" formControlName="employmentEndDate" label="end">
				</nc-datepicker>
			</div>
			<nc-input
				[id]="'premium-percentage-of-employment'"
				[mask]="{ mask: '000' }"
				[formGroup]="formGroup"
				formControlName="employmentPercentage"
				label="percentageOfEmployment"
			>
			</nc-input>
			<nc-input
				[id]="'premium-annual-salary'"
				[mask]="{ mask: '000000000' }"
				[formGroup]="formGroup"
				formControlName="employmentAnnualSalary"
				label="annualSalaryInYearOfInsuredEvent"
			>
			</nc-input>
		</div>
		<div fxLayout="column" fxLayoutGap="10px" *ngIf="company.temporaryContract" class="margin-top-10">
			<div fxLayout="row" fxLayoutGap="10px" fxLayout.sm="column" fxLayout.xs="column" class="flex-wrap">
				<nc-toggle-buttons
					[formGroup]="formGroup"
					[options]="employmentRegistration"
					label="firstDeploymentRegistration"
					formControlName="firstDeploymentRegistration"
				>
				</nc-toggle-buttons>
				<nc-toggle-buttons
					[formGroup]="formGroup"
					[options]="employmentSubject"
					label="firstDeploymentSubject"
					formControlName="firstDeploymentSubject"
				>
				</nc-toggle-buttons>
			</div>

			<div fxLayout="row" fxLayoutGap="10px" class="flex-wrap">
				<nc-datepicker
					[id]="'premium-first-employment-date'"
					[formGroup]="formGroup"
					formControlName="firstDeploymentDate"
					label="firstDeployment"
				>
				</nc-datepicker>
				<nc-datepicker
					[id]="'premium-last-employment-date'"
					[formGroup]="formGroup"
					formControlName="lastDeploymentDate"
					label="lastDeployment"
				>
				</nc-datepicker>
			</div>
		</div>
	</div>
	<div class="sub-section">
		<ng-container *ngIf="formGroup.get('absenceType').value !== 'ACCIDENT'">
			<span class="sub-section-title">{{ 'notificationSentTo' | translate }}</span>
			<div fxLayout="column" fxLayoutGap="10px">
				<div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column">
					<div class="toggle-button-side-label">{{ 'dailySicknessBenefitsInsurance' | translate }}</div>
					<nc-toggle-buttons [formGroup]="formGroup" [options]="flags" formControlName="hasDailySicknessBenefitsInsurance">
					</nc-toggle-buttons>
				</div>
				<ng-container *ngIf="formGroup.get('hasDailySicknessBenefitsInsurance').value === 'yes'">
					<div fxLayout="row" fxLayout.lt-xl="column" fxLayoutGap="10px" fxLayoutGap.lt-xl="0px">
						<nc-input
							[id]="'premium-sickness-insurer'"
							fxFlex="50"
							[formGroup]="formGroup"
							label="sicknessInsurer"
							formControlName="dailySicknessBenefitsInsuranceCompany"
						>
						</nc-input>
						<nc-input
							[id]="'premium-daily-sickness-sinister-reference'"
							fxFlex="50"
							[formGroup]="formGroup"
							label="sinisterReference"
							formControlName="dailySicknessBenefitsInsuranceSinisterReference"
						>
						</nc-input>
					</div>
				</ng-container>
			</div>
		</ng-container>
		<ng-container *ngIf="formGroup.get('absenceType').value === 'ACCIDENT'">
			<span class="sub-section-title">{{ 'notificationSentTo' | translate }}</span>
			<div fxLayout="column" fxLayoutGap="10px">
				<div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column">
					<div class="toggle-button-side-label">{{ 'accidentInsurance' | translate }}</div>
					<nc-toggle-buttons [formGroup]="formGroup" [options]="flags" formControlName="hasAccidentInsurance"> </nc-toggle-buttons>
				</div>
				<ng-container *ngIf="formGroup.get('hasAccidentInsurance').value === 'yes'">
					<div fxLayout="row" fxLayout.lt-xl="column" fxLayoutGap="10px" fxLayoutGap.lt-xl="0px">
						<nc-input
							[id]="'premium-accident-insurance-company'"
							fxFlex="50"
							[formGroup]="formGroup"
							label="accidentInsurer"
							formControlName="accidentInsuranceCompany"
						>
						</nc-input>
						<nc-input
							[id]="'premium-accident-insurance-sinister-reference'"
							fxFlex="50"
							[formGroup]="formGroup"
							label="sinisterReference"
							formControlName="accidentInsuranceSinisterReference"
						>
						</nc-input>
					</div>
				</ng-container>
			</div>
		</ng-container>
	</div>
</div>
