import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { State } from './state/app.state';
import { SettingsSelect } from './state/settings/action';
import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root',
})
export class AppReadyGuard implements CanActivate {
	constructor(private router: Router, private store: Store<State>) {}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
		return this.store.select(SettingsSelect.isReady).pipe(
			map((result) => {
				if (!result) {
					this.router.navigateByUrl('/unavailable');
				}
				return result;
			})
		);
	}
}
