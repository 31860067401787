import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { EnvironmentLoader } from '../core/config/environment-loader';

@Component({
	templateUrl: './document.component.html',
	styleUrls: ['./document.component.scss'],
})
export class DocumentComponent implements OnInit {
	pdfUrl: string;
	language: string;
	fileName: string;

	constructor(private location: Location, private activatedRoute: ActivatedRoute, private readonly environmentLoader: EnvironmentLoader) {}

	ngOnInit(): void {
		this.activatedRoute.params.subscribe((params) => {
			this.language = 'en-US';
			this.fileName = `${params.type}_${params.language}`;
			this.pdfUrl = `${this.environmentLoader.getEnvironment().API_URL}/public-form-document/${params.language}/${params.type}`;
		});
	}

	onBack = (): void => {
		this.location.back();
	};
}
