import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { CanDeactivateFormComponent, Option } from 'nc-shared';
import { EnumUtils } from 'nc-utils';
import { UserTitleEnumeration } from '../../model/user-title.enumeration';
import { Store } from '@ngrx/store';
import { UserPageAction } from '../../state/action';
import { TranslateService } from '@ngx-translate/core';
import { validatePhone } from '../../validation/request-form-validator';
import { SettingsSelect } from '../../../state/settings/action';
import { PhoneUtils } from '../../../shared/utils/phone-utils.component';

@Component({
	templateUrl: './hr-app-access-request-form.component.html',
	styleUrls: ['./hr-app-access-request-form.component.scss'],
})
export class HrAppAccessRequestFormComponent extends CanDeactivateFormComponent implements OnInit {
	genders: Option[];
	title: string;
	language: string;

	constructor(
		@Inject(MAT_DIALOG_DATA) public data,
		private formBuilder: UntypedFormBuilder,
		private store: Store,
		private translateService: TranslateService
	) {
		super();
		this.title = this.data.title;
	}

	ngOnInit(): void {
		this.genders = EnumUtils.toItems(UserTitleEnumeration);
		this.formGroup = this.formBuilder.group({
			title: ['', []],
			gender: ['MALE', [Validators.required]],
			firstName: ['', [Validators.required]],
			lastName: ['', [Validators.required]],
			company: ['', [Validators.required]],
			contractId: [null as number, [Validators.required]],
			phone: ['', [Validators.required, validatePhone()]],
			email: ['', [Validators.required, Validators.email]],
			comment: ['', []],
			language: ['', []],
		});
		this.formGroup.controls.title.setValue(this.title);

		this.store.select(SettingsSelect.getLanguage).subscribe((data) => {
			this.language = data;
		});
	}

	onPhoneCheck(event: KeyboardEvent): void {
		const input = event.target as HTMLInputElement;
		PhoneUtils.formatPhoneNumber(input, this.formGroup);
	}

	submit(): void {
		const title = this.translateService.instant(this.formGroup.controls.title.value);
		this.formGroup.controls.title.setValue(title);
		this.formGroup.controls.language.setValue(this.language);
		const data = this.formGroup.getRawValue();
		this.store.dispatch(UserPageAction.requestForm({ data }));
	}

	onCancel = (): void => {
		this.store.dispatch(UserPageAction.closeDialog());
	};
}
