import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { Option } from 'nc-shared';
import { AbsenceCaseTypeEnumeration } from '../../../model/enumerations/absence-case-type-enumeration';
import { EnumUtils } from 'nc-utils';

@Component({
	selector: 'ea-message-type',
	templateUrl: './message-type.component.html',
	styleUrls: ['./message-type.component.scss'],
})
export class MessageTypeComponent implements OnInit {
	@Input() formGroup: UntypedFormGroup;

	messageTypes: Option[];

	validTo: AbstractControl;
	validFrom: AbstractControl;
	percentage: AbstractControl;
	messageType: AbstractControl;

	insuredPerson: AbstractControl;
	referenceNumber: AbstractControl;

	constructor() {
		this.messageTypes = EnumUtils.toItems(AbsenceCaseTypeEnumeration);
	}

	ngOnInit(): void {
		this.validTo = this.formGroup.get('validTo');
		this.validFrom = this.formGroup.get('validFrom');
		this.percentage = this.formGroup.get('percentage');
		this.messageType = this.formGroup.get('absenceCaseType');
		this.referenceNumber = this.formGroup.get('referenceNumber');
		this.insuredPerson = this.formGroup.get('refEmployee');

		this.clearIncapacityToWorkValues();

		this.messageType.valueChanges.subscribe((type) => {
			if (type === 'CLOSED') {
				this.clearInsuredPersonSelection();
				this.clearIncapacityToWorkValues();
			} else if (type === 'PROLONGATION') {
				this.clearInsuredPersonSelection();
			} else {
				this.clearReferenceNumberSelection();
			}
		});
	}

	private clearReferenceNumberSelection(): void {
		this.referenceNumber.patchValue(null);
	}

	private clearInsuredPersonSelection(): void {
		this.insuredPerson.patchValue(null);
	}

	private clearIncapacityToWorkValues(): void {
		this.validTo.patchValue('');
		this.validTo.markAsPristine();
		this.validFrom.patchValue('');
		this.validFrom.markAsPristine();
		this.percentage.patchValue('');
		this.percentage.markAsPristine();
	}
}
