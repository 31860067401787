<form fxLayout="column" fxFill [formGroup]="formGroup">
	<nc-main-content>
		<nc-form-content [center]="false">
			<div fxLayout="column">
				<div fxLayout="column">
					<ea-employer-details [company]="company$ | async"></ea-employer-details>
					<ea-message-type [formGroup]="formGroup"></ea-message-type>
				</div>
				<ng-container [ngSwitch]="formGroup.get('absenceCaseType').value">
					<ea-new-message *ngSwitchCase="'NEW'" [formGroup]="formGroup" [absence]="absence$ | async" [company]="company$ | async">
					</ea-new-message>
					<ea-prolongation-message *ngSwitchCase="'PROLONGATION'" [formGroup]="formGroup"> </ea-prolongation-message>
					<ea-close-message *ngSwitchCase="'CLOSED'" [formGroup]="formGroup"> </ea-close-message>
					<ea-documents
						[id]="'uploaded-documents'"
						[buttonId]="'open-dialog'"
						[downloadButtonId]="'download-documents-button'"
						[documentsFormArray]="formGroup.get('documents')"
					>
					</ea-documents>
				</ng-container>
			</div>
		</nc-form-content>
	</nc-main-content>
	<ea-footer [onNext]="onSubmit" onNextLabel="create" [onCancel]="onCancel" onCancelLabel="cancel" [formGroup]="formGroup"> </ea-footer>
</form>
