import { createFeatureSelector, createSelector } from '@ngrx/store';
import { UserState } from '../user.state';
import { StringsUtils } from 'nc-utils';

export const USER_FEATURE_STATE_NAME = 'user';
const FEATURE_STATE = createFeatureSelector<UserState>(USER_FEATURE_STATE_NAME);

export const getAccess = createSelector(FEATURE_STATE, (state) => state.access);
export const getToken = createSelector(FEATURE_STATE, (state) => state.access.token);
export const getCompanyId = createSelector(FEATURE_STATE, (state) => state.companyId);
export const getCredentials = createSelector(FEATURE_STATE, (state) => state.credentials);
export const isVerified = createSelector(FEATURE_STATE, (state) => state.verified);
export const hasAccess = createSelector(FEATURE_STATE, (state) => state.access.granted && StringsUtils.isNotEmpty(state.access.token));
export const getContact = createSelector(FEATURE_STATE, (state) => state.contact);
