<form fxLayout="column" fxFill [formGroup]="formGroup">
	<nc-main-content>
		<nc-form-content class="form-content-center">
			<nc-form-title label="pleaseEnter6digitSMSCode"></nc-form-title>
			<nc-input
				[id]="'sms-code'"
				ncAutofocus
				[shouldAutofocus]="true"
				[formGroup]="formGroup"
				textAlign="center"
				[mask]="{ mask: '0 0 0 0 0 0', lazy: false }"
				formControlName="code"
				label="smsPlaceholder6digitSMSCode"
				placeholder="smsPlaceholder6digitSMSCode"
			>
			</nc-input>
			<nc-button [id]="'resend'" [onClick]="onResend" [isRaised]="false" color="primary" label="resendSMSCode"></nc-button>
		</nc-form-content>
	</nc-main-content>
	<ea-footer
		[formGroup]="formGroup"
		[onNext]="onSubmit"
		onNextLabel="next"
		[onCancel]="onBack"
		onCancelLabel="back"
		[onNextDisabled]="formGroup.invalid"
	>
	</ea-footer>
</form>
