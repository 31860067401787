import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { State } from '../../state/app.state';
import { UserSelect } from '../../user/state/action';
import { first, mergeMap } from 'rxjs/operators';

/**
 * Each http request goes through this interceptor.
 * On every request we attach token.
 */
@Injectable()
export class RequestHttpInterceptor implements HttpInterceptor {
	constructor(private store: Store<State>) {}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return this.store.select(UserSelect.getToken).pipe(
			first(),
			mergeMap((token) => next.handle(this.createHeaders(request, token)))
		);
	}

	/**
	 * Adds token and necessary header options for HTTP request
	 * @param request Http request to be modified
	 * @param token Authorization token
	 */
	private createHeaders(request: HttpRequest<any>, token: string): HttpRequest<any> {
		return request.clone({
			setHeaders: {
				Authorization: `Bearer ${token}`,
				Accept: 'application/json',
			},
		});
	}
}
