<div class="unavailable-screen">
	<div class="unavailable-center">
		<img src="assets/images/logo-tellco.png" class="splash-screen-image responsive" />
		<div class="unavailable-width center">
			{{ 'unavailableMessage' | translate }}
		</div>
		<div class="unavailable-width center">
			<nc-button [id]="'retry'" [onClick]="onRetry" label="retry"></nc-button>
		</div>
	</div>
</div>
