<div fxLayout="row" fxLayoutGap="10px" fxLayout.sm="column" fxLayout.xs="column" [formGroup]="formGroup" class="section">
	<div fxFlex="50" fxLayout="column" fxLayoutGap="10px">
		<nc-toggle-buttons [formGroup]="formGroup" [options]="declarationType" label="absenceDeclaration" formControlName="categoryType">
		</nc-toggle-buttons>
		<div fxLayout="row" class="no-margin-bottom">
			<nc-quick-search
				[textInputId]="'new-message-insured-person'"
				[resetButtonId]="'new-message-insured-person-reset'"
				[undoButtonId]="'new-message-insured-person-undo'"
				ncAutofocus
				[shouldAutofocus]="true"
				fxFlex="100"
				formControlName="refEmployee"
				label="insuredPerson"
				[formGroup]="formGroup"
				placeholder="insuredPersonPleaceholder"
				[displayOptionFunc]="displayFunc"
				[fetchAll]="filterItems"
			>
				<ng-template let-item>
					<span class="mat-option-title">{{ item.text | split : ';' : 0 }}</span
					><br />
					<span class="mat-option-footer">{{ item.text | split : ';' : 1 }}</span>
				</ng-template>
			</nc-quick-search>
			<ng-container *ngIf="selectedEmployee.value">
				<mat-icon class="more-details-action" (click)="open()">info</mat-icon>
			</ng-container>
		</div>
		<ng-container *ngIf="selectedEmployee.value">
			<ea-insurance-period fxLayoutGap="5px" [absence]="absence"></ea-insurance-period>
		</ng-container>
		<div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="10px" class="flex-wrap">
			<nc-datepicker [id]="'new-message-valid-from'" [formGroup]="formGroup" formControlName="validFrom" label="validFrom"></nc-datepicker>
			<nc-datepicker [id]="'new-message-valid-to'" [formGroup]="formGroup" formControlName="validTo" label="validTo"></nc-datepicker>
		</div>
		<nc-input
			[id]="'new-message-percentage'"
			[formGroup]="formGroup"
			formControlName="percentage"
			label="percentage"
			[mask]="{ mask: '000' }"
		></nc-input>
		<nc-textarea [id]="'new-message-comment'" [formGroup]="formGroup" formControlName="comment" label="comment" maxLength="300"></nc-textarea>

		<div class="flex-wrap" fxLayout="row" fxLayout.md="column" fxLayout.sm="column" fxLayout.xs="column" fxLayoutGap="10px">
			<nc-toggle-buttons label="absenceType" [formGroup]="formGroup" [options]="absenceType" formControlName="absenceType"> </nc-toggle-buttons>

			<ng-container [ngSwitch]="formGroup.get('absenceType').value">
				<nc-toggle-buttons
					*ngSwitchCase="'ACCIDENT'"
					label="absenceSubType"
					[formGroup]="formGroup"
					[options]="absenceAccidentSubType"
					formControlName="absenceSubType"
				>
				</nc-toggle-buttons>
				<nc-toggle-buttons
					*ngSwitchCase="'SICKNESS'"
					label="absenceSubType"
					[formGroup]="formGroup"
					[options]="absenceSicknessSubType"
					formControlName="absenceSubType"
				>
				</nc-toggle-buttons>
				<nc-datepicker
					[id]="'new-message-pregnancy'"
					*ngSwitchCase="'PREGNANCY'"
					[formGroup]="formGroup"
					formControlName="pregnancyDueDate"
					label="pregnancyDueDate"
					class="pregnancy-date-picker"
				>
				</nc-datepicker>
			</ng-container>
		</div>
	</div>
	<ea-premium-details fxFlex="50" *ngIf="formGroup.get('categoryType').value === 'PREMIUM'" [formGroup]="formGroup" [company]="company">
	</ea-premium-details>
</div>
