import { ActionReducer, ActionReducerMap, MetaReducer } from '@ngrx/store';
import { State } from './app.state';
import { localStorageSync } from 'ngrx-store-localstorage';
import { settingsReducer } from './settings/settings.reducer';
import { userReducer } from '../user/state/user.reducer';

export const appReducers: ActionReducerMap<State> = {
	user: userReducer,
	settings: settingsReducer,
};

function localStorageSyncReducer(reducer: ActionReducer<State>): ActionReducer<State> {
	return localStorageSync({
		keys: [{ settings: ['language'] }, { user: ['credentials', 'access', 'companyId', 'contact', 'verified'] }],
		rehydrate: true,
	})(reducer);
}

export const appMetaReducers: Array<MetaReducer<State, any>> = [localStorageSyncReducer];
