<form ncConnectForm fxLayout="column" fxFill [formGroup]="formGroup" [connectForm]="credentials$ | async">
	<nc-main-content>
		<div fxLayout="column" fxLayoutGap="15px">
			<div class="font-color sign-in-info-section" innerHTML="{{ 'welcomeNote' | translate }}"></div>
			<nc-form-content>
				<nc-form-title label="employerData"> </nc-form-title>
				<nc-input
					[id]="'contract-number'"
					ncAutofocus
					[shouldAutofocus]="true"
					[formGroup]="formGroup"
					[mask]="{ mask: '000000000' }"
					tooltip="clientNoTooltip"
					formControlName="contractNumber"
					placeholder="clientNoPlaceholder"
					label="contractNo"
				>
				</nc-input>
				<nc-input
					[id]="'company-name'"
					[formGroup]="formGroup"
					[mask]="{ mask: '***' }"
					tooltip="companyName3LettersTooltip"
					formControlName="first3Letters"
					placeholder="clientNamePlaceholder"
					label="companyName3Letters"
				>
				</nc-input>
				<div fxLayout="row" fxLayoutAlign="space-between center">
					<nc-checkbox [id]="'remember'" [formGroup]="formGroup" color="primary" formControlName="remember" label="save"> </nc-checkbox>
					<nc-submit-button
						[id]="'next'"
						color="primary"
						dialogLabel="processing"
						[formGroup]="formGroup"
						(buttonClick)="onSubmit()"
						[buttonLabel]="'submitForm'"
						[disabled]="formGroup.invalid"
					>
					</nc-submit-button>
				</div>
			</nc-form-content>
			<div class="font-color sign-in-info-section">
				<div fxLayout="row">
					<img id="eAbsenceLogo" fxLayout="column" src="assets/images/eAbsence_logo.svg" />
					<div fxLayout="column">
						<p id="eAbsenceUseItNowForFree">{{ 'eAbsenceUseItNowForFree' | translate }}</p>
					</div>
				</div>
				<p>{{ 'tellcoWebFormInfoText1' | translate }}</p>
				<p class="tellcoWebFormInfoText" innerHTML="- {{ 'tellcoWebFormInfoText2' | translate }}"></p>
				<p class="tellcoWebFormInfoText" innerHTML="- {{ 'tellcoWebFormInfoText3' | translate }}"></p>
				<p class="tellcoWebFormInfoText" innerHTML="- {{ 'tellcoWebFormInfoText4' | translate }}"></p>
			</div>
			<div fxLayout="row" class="button-section">
				<button id="orderAbsenceAccess" label="orderAbsenceAccess" (click)="openForm('orderAbsenceAccess')">
					{{ 'orderAbsenceAccess' | translate }}
				</button>
				<button id="requestADemo" label="requestADemo" (click)="openForm('requestADemo')">{{ 'requestADemo' | translate }}</button>
				<button id="contactUs" label="contactUs" (click)="openForm('contactUs')">{{ 'contactUs' | translate }}</button>
			</div>
		</div>
	</nc-main-content>
	<ea-footer message="noteOnPublicFormSessionExpirationPeriod"> </ea-footer>
</form>
