import { NgModule } from '@angular/core';
import { NoPreloading, RouterModule } from '@angular/router';
import { SignInGuard } from './user/sign-in.guard';
import { SignInComponent } from './user/component/sign-in/sign-in.component';
import { DocumentComponent } from './document/document.component';
import { UnavailableComponent } from './shared/component/error/unavailable/unavailable.component';
import { AppReadyGuard } from './app-ready.guard';
import { VerificationGuard } from './user/verification.guard';
import { NotFoundComponent } from './shared/component/error/not-found/not-found.component';
import { ContactInformationComponent } from './user/component/contact-information/contact-information.component';
import { SmsVerificationComponent } from './user/component/sms-verification/sms-verification.component';
import { VersionComponent } from './shared/component/version/version.component';
import { CanDeactivateGuard } from 'nc-shared';
import { AbsenceComponent } from './absence/component/absence.component';

@NgModule({
	imports: [
		RouterModule.forRoot(
			[
				{
					path: '',
					component: SignInComponent,
					canActivate: [AppReadyGuard],
					canDeactivate: [CanDeactivateGuard],
					data: { title: 'reportHomePage.title' },
				},
				{
					path: 'unavailable',
					component: UnavailableComponent,
					data: { title: 'unavailable' },
				},
				{
					path: 'status/info',
					component: VersionComponent,
					data: { title: 'Version' },
				},
				{
					path: '404',
					component: NotFoundComponent,
					data: { title: '404' },
				},
				{
					path: 'document/:language/:type',
					canActivate: [AppReadyGuard],
					component: DocumentComponent,
					data: { title: 'documents' },
				},
				{
					path: 'verification/contact',
					component: ContactInformationComponent,
					canActivate: [AppReadyGuard, SignInGuard],
					canDeactivate: [CanDeactivateGuard],
					data: { title: 'reportContactPage.title' },
					loadChildren: () => import('./user/user.module').then((m) => m.UserModule),
				},
				{
					path: 'verification/sms',
					component: SmsVerificationComponent,
					canActivate: [AppReadyGuard, SignInGuard],
					canDeactivate: [CanDeactivateGuard],
					data: { title: 'reportSMSVerificationFormPage.title' },
					loadChildren: () => import('./user/user.module').then((m) => m.UserModule),
				},
				{
					path: 'absence/notification',
					component: AbsenceComponent,
					canActivate: [AppReadyGuard, SignInGuard, VerificationGuard],
					canDeactivate: [CanDeactivateGuard],
					data: { title: 'reportNewCaseFormPage.title' },
					loadChildren: () => import('./absence/absence.module').then((m) => m.AbsenceModule),
				},
				{
					path: '',
					redirectTo: '',
					pathMatch: 'full',
				},
				{ path: '**', redirectTo: '404' },
			],
			{ preloadingStrategy: NoPreloading }
		),
	],
	exports: [RouterModule],
})
export class AppRoutingModule {}
