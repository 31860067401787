import { Component, Input } from '@angular/core';
import * as moment from 'moment/moment';
import { ApiDateFormat, parseDate } from 'nc-utils';

@Component({
	selector: 'ea-banner',
	templateUrl: './banner.component.html',
	styleUrls: ['./banner.component.scss'],
})
export class BannerComponent {
	@Input() message = 'maintenanceInfo';
	@Input() bannerDateFrom = '20.06.2024 06:00:00';
	@Input() bannerDateTo = '28.06.2024 06:00:00';

	checkIfBannerIsVisible(): boolean {
		const bannerDateFrom = parseDate(this.bannerDateFrom, ApiDateFormat);
		const bannerDateTo = parseDate(this.bannerDateTo, ApiDateFormat);
		const currentDate = moment();
		return currentDate.isSameOrAfter(bannerDateFrom) && currentDate.isSameOrBefore(bannerDateTo);
	}
}
