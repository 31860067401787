import { createReducer, on } from '@ngrx/store';
import { USER_INITIAL_STATE, UserState } from './user.state';
import { UserApiAction, UserPageAction } from './action';

export const userReducer = createReducer<UserState>(
	USER_INITIAL_STATE,
	on(UserApiAction.signInSubmitSuccess, (state, action): UserState => {
		return {
			...state,
			access: {
				token: action.access.token,
				granted: action.access.granted,
				message: action.access.message,
			},
			credentials: {
				contractNumber: action.credentials.remember ? action.credentials.contractNumber : '',
				first3Letters: action.credentials.remember ? action.credentials.first3Letters : '',
				remember: action.credentials.remember,
			},
			companyId: action.credentials.contractNumber,
		};
	}),
	on(UserApiAction.signInSubmitFailed, (state, action): UserState => {
		return {
			...state,
			access: {
				token: '',
				granted: false,
				message: action.error,
			},
		};
	}),
	on(UserPageAction.initialize, (state): UserState => {
		return {
			...state,
			access: {
				token: '',
				message: '',
				granted: false,
			},
			verified: false,
		};
	}),
	on(UserApiAction.cancelSuccess, (state): UserState => {
		return {
			...state,
			access: {
				token: '',
				message: '',
				granted: false,
			},
		};
	}),
	on(UserApiAction.cancelFailed, (state, action): UserState => {
		return {
			...state,
			access: {
				token: '',
				granted: false,
				message: action.error,
			},
		};
	}),
	on(UserApiAction.setVerified, (state, action): UserState => {
		return {
			...state,
			verified: action.verified,
		};
	}),
	on(UserPageAction.verificationSubmit, (state, action): UserState => {
		return {
			...state,
			contact: action.contact,
		};
	})
);
