<div fxLayout="row" class="margin-top-15 sub-section">
	<span class="sub-section-title"
		><nc-label [id]="'documents'" value="documents" tooltip="caseUploadDocumntTooltip" isOutlineIcon="true"></nc-label
	></span>
	<nc-documents
		fxFlex="100"
		[id]="id"
		[buttonId]="buttonId"
		[downloadButtonId]="downloadButtonId"
		[formArray]="documentsFormArray"
		[documentTypes$]="documentTypes$"
		[hideMultiDownload]="true"
		[hideDownload]="true"
		[hidePreview]="true"
	>
	</nc-documents>
</div>
