import { Observable } from 'rxjs';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { filter, map, shareReplay, switchMap } from 'rxjs/operators';

/**
 * Class holds different types methods which are related to route change and features which depend on it.
 */
export class RouteChange {
	/**
	 * Returns observable which is observing router change and gets proper translation of title from activated route data.
	 * @param router Angular router -> First observer for router change.
	 * @param activatedRoute Angular activated rout -> It holds title code (data: {title: '...'})
	 * @param translateService From @ngx-translate library service. Where we use stream method to extract translation.
	 */
	static getTitle$(router: Router, activatedRoute: ActivatedRoute, translateService: TranslateService): Observable<any> {
		const title$ = router.events.pipe(
			filter((event) => event instanceof NavigationEnd),
			map(() => activatedRoute),
			map((route) => this.getActiveRoute(route)),
			switchMap((route) => route.data),
			map((data) => data.title),
			switchMap((title) => translateService.stream(title)),
			shareReplay(1)
		);

		return title$;
	}

	/**
	 * Gets current active route.
	 * Here we need to go to the "last" first child activated route
	 * @param activatedRoute Angular activated rout -> It holds title code (data: {title: '...'})
	 */
	static getActiveRoute(activatedRoute: ActivatedRoute): ActivatedRoute {
		while (activatedRoute.firstChild) {
			activatedRoute = activatedRoute.firstChild;
		}

		return activatedRoute;
	}
}
