<nc-confirmation-dialog titleLabel="employerData" buttonLabel="close">
	<table class="readonly-form" *ngIf="employer$ | async as data">
		<tr>
			<td>{{ 'contractNumber' | translate }}:</td>
			<td>{{ data.fullDetails.neoId }}</td>
		</tr>
		<tr>
			<td>{{ 'employer' | translate }}:</td>
			<td>{{ data.fullDetails.name }}</td>
		</tr>
		<tr>
			<td>&nbsp;</td>
			<td>&nbsp;</td>
		</tr>
		<tr>
			<td>{{ 'street' | translate }}:</td>
			<td>{{ data.fullDetails.street }}</td>
		</tr>
		<tr>
			<td>{{ 'postbox' | translate }}:</td>
			<td>{{ data.fullDetails.postbox }}</td>
		</tr>
		<tr>
			<td>{{ 'zip' | translate }}:</td>
			<td>{{ data.fullDetails.zip }}</td>
		</tr>
		<tr>
			<td>{{ 'country' | translate }}:</td>
			<td>{{ data.fullDetails.country | translate }}</td>
		</tr>
		<tr>
			<td>{{ 'canton' | translate }}:</td>
			<td>{{ data.fullDetails.canton }}</td>
		</tr>
	</table>
</nc-confirmation-dialog>
