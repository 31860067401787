<div class="footer-content" fxLayout="column" fxLayoutAlign="center">
	<div class="footer-navigation" fxLayout="row" fxLayoutAlign="center">
		<div *ngIf="onCancel" class="cancel">
			<nc-button [id]="'cancel'" color="primary" [isRaised]="true" [onClick]="onCancel" label="{{ onCancelLabel }}"> </nc-button>
		</div>
		<div *ngIf="message" class="message" fxFlex="90">
			<i fxHide fxShow.gt-md>* {{ message | translate }}</i>
		</div>
		<div *ngIf="!message" class="message" fxFlex="90">&nbsp;</div>
		<div *ngIf="onNext" class="next">
			<nc-submit-button
				[id]="'next'"
				color="primary"
				dialogLabel="processing"
				[formGroup]="formGroup"
				(buttonClick)="onNext()"
				[buttonLabel]="onNextLabel"
				[disabled]="onNextDisabled"
			>
			</nc-submit-button>
		</div>
	</div>
</div>
