export enum MaritalStatusEnumeration {
	UNKNOWN = 'unknown',
	SINGLE = 'single',
	MARRIED = 'married',
	WIDOWED = 'widowed',
	DIVORCED = 'divorced',
	SEPARATED = 'separated',
	REGISTERED_PARTNERSHIP = 'registeredPartnership',
	PARTNERSHIP_DISSOLVED_BY_LAW = 'partnershipDissolvedByLaw',
	PARTNERSHIP_DISSOLVED_BY_DEATH = 'partnershipDissolvedByDeath',
	PARTNERSHIP_DISSOLVED_BY_DECLARATION_OF_LOST = 'partnershipDissolvedByDeclarationOfLost',
	CONCUBINE = 'concubine',
}
