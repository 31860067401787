import { createAction, props } from '@ngrx/store';
import { Absence } from '../../model/absence';
import { SaveResponse } from 'nc-utils';

export const create = createAction('[Absence] Create absence', props<{ absence: Absence }>());
export const forceNew = createAction('[Absence] Force new absence');
export const cancel = createAction('[Absence] Cancel absence');
export const update = createAction('[Absence] Update form values', props<{ absence: Absence }>());
export const fetchCompany = createAction('[Absence] Get company details');
export const fetchEmployee = createAction('[Absence] Fetch employee', props<{ id: string }>());
export const fetchEmployees = createAction('[Absence] Fetch employees for new case', props<{ companyId: string }>());
export const searchCase = createAction('[Absence] Fetch employees for new case with company id', props<{ companyId: string }>());
export const clearEmployee = createAction('[Absence] Clear employee');

export const openAbsenceWarningDialog = createAction('[Absence] Warning dialog', props<{ response: SaveResponse }>());
export const populateErrors = createAction('[Absence] Populate errors');
export const openConfirmationDialog = createAction('[Absence] Confirmation dialog');
export const switchToProlongation = createAction('[Absence] Switch to prolongation', props<{ code: string }>());
