import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { BaseComponent, Option } from 'nc-shared';
import { Observable, of, take } from 'rxjs';
import { Store } from '@ngrx/store';
import { State } from '../../../state/absence.state';
import { AbsenceService } from '../../../service/absence.service';
import { filter, takeUntil } from 'rxjs/operators';
import { AbsencePageAction, AbsenceSelectAction } from '../../../state/action';
import { FlagEnumeration } from '../../../model/enumerations/flag.enumeration';
import { AbsenceTypeEnumeration } from '../../../model/enumerations/absence-type.enumeration';
import { AbsenceDeclarationTypeEnumeration } from '../../../model/enumerations/absence-declaration-type.enumeration';
import { AbsenceAccidentSubTypeEnumeration } from '../../../model/enumerations/absence-accident-sub-type.enumeration';
import { AbsenceSicknessSubTypeEnumeration } from '../../../model/enumerations/absence-sickness-sub-type.enumeration';
import { EmploymentRegistrationEnumeration } from '../../../model/enumerations/employment-registration.enumeration';
import { EmploymentSubjectEnumeration } from '../../../model/enumerations/employment-subject.enumeration';
import { Absence } from '../../../model/absence';
import { InsuredPersonDialogComponent } from '../../dialog/insured-person-dialog/insured-person-dialog.component';
import { Company } from '../../../model/company';
import { DialogService, EnumUtils, StringsUtils } from 'nc-utils';

@Component({
	selector: 'ea-new-message',
	templateUrl: './new-message.component.html',
	styleUrls: ['./new-message.component.scss'],
})
export class NewMessageComponent extends BaseComponent implements OnInit {
	@Input() formGroup: UntypedFormGroup;
	@Input() absence: Absence;
	@Input() company: Company;

	employees$: Observable<Option[]>;

	selectedEmployee: UntypedFormControl;

	flags: Option[];
	absenceType: Option[];
	declarationType: Option[];
	employmentSubject: Option[];
	absenceAccidentSubType: Option[];
	absenceSicknessSubType: Option[];
	employmentRegistration: Option[];

	constructor(public dialogService: DialogService, private store: Store<State>, private absenceService: AbsenceService) {
		super();
	}

	displayFunc = (option: Option) => (option ? option.text.split(';')[0] : '');

	open(): void {
		this.dialogService.open(InsuredPersonDialogComponent);
	}

	ngOnInit(): void {
		this.selectedEmployee = this.formGroup.get('refEmployee') as UntypedFormControl;
		this.employees$ = this.store.select(AbsenceSelectAction.getEmployees);

		this.fetchEmployees();

		this.flags = EnumUtils.toItems(FlagEnumeration);
		this.absenceType = EnumUtils.toItems(AbsenceTypeEnumeration);
		this.declarationType = EnumUtils.toItems(AbsenceDeclarationTypeEnumeration);
		this.absenceAccidentSubType = EnumUtils.toItems(AbsenceAccidentSubTypeEnumeration);
		this.absenceSicknessSubType = EnumUtils.toItems(AbsenceSicknessSubTypeEnumeration);
		this.employmentRegistration = EnumUtils.toItems(EmploymentRegistrationEnumeration);
		this.employmentSubject = EnumUtils.toItems(EmploymentSubjectEnumeration);

		this.selectedEmployee.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((id) => {
			if (StringsUtils.isNotEmpty(id)) {
				this.store.dispatch(AbsencePageAction.fetchEmployee({ id }));
			}
		});

		const absenceSubTypeControl = this.formGroup.get('absenceSubType');
		this.formGroup.get('absenceType').valueChanges.subscribe((type) => {
			switch (type) {
				case 'ACCIDENT':
					absenceSubTypeControl.patchValue('WORK');
					break;
				case 'SICKNESS':
					absenceSubTypeControl.patchValue('PSYCHIC');
					break;
				default:
					absenceSubTypeControl.patchValue('');
					break;
			}
		});
	}

	filterItems = (term) => {
		if (term.length > 1) {
			let employeesRes: Option[] = [];
			this.employees$.pipe(filter((employees) => employees != null)).subscribe((employees) => {
				employeesRes = employees.filter((item) => item.text.toLowerCase().includes(term.toLowerCase()));
			});

			return of(employeesRes);
		}
	};

	fetchEmployees(): void {
		this.store
			.select(AbsenceSelectAction.getCompany)
			.pipe(
				takeUntil(this.destroy$),
				filter((company) => company.id !== '0')
			)
			.subscribe((company) => {
				this.company = company;
				this.store.dispatch(AbsencePageAction.fetchEmployees({ companyId: this.company.id }));
			});
	}
}
