import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Company } from '../model/company';
import { map } from 'rxjs/operators';
import { Employee } from '../model/employee';
import { CaseReferenceDetails } from '../model/case-reference-details/case-reference-details';
import { Absence } from '../model/absence';
import { ContactDetails } from '../../user/model/contact-details';
import { RequestModelFactory } from './request-model.factory';
import { Option } from 'nc-shared';
import { MaritalStatusEnumeration } from '../model/enumerations/marital-status.enumeration';
import { LanguageEnumeration } from '../model/enumerations/language.enumeration';
import { GenderEnumeration } from '../model/enumerations/gender-enumeration';
import { SaveResponse } from 'nc-utils';
import { QueryResponse, toOption } from '../../shared/model/QueryResponse';
import { EnvironmentLoader } from '../../core/config/environment-loader';

/**
 * Interface for absence notification actions and data manipulation.
 */
@Injectable({
	providedIn: 'root',
})
export class AbsenceService {
	constructor(private http: HttpClient, private factory: RequestModelFactory, private environmentLoader: EnvironmentLoader) {}

	private readonly API_URL = this.environmentLoader.getEnvironment().API_URL;

	/**
	 * Gets company details.
	 * @param companyId Company's unique identifier
	 */
	getCompany(companyId: string): Observable<Company> {
		return this.http.get<Company>(`${this.API_URL}/absence-notification/company-details/${companyId}`).pipe(
			map((company) => {
				company.fullDetails.country = 'country_' + company.fullDetails.country;
				return company;
			})
		);
	}

	/**
	 * Gets employee details.
	 * @param employeeId Unique employee identifier
	 * @param language User's language
	 */
	getEmployee(employeeId: string, language: string): Observable<Employee> {
		return this.http.get<Employee>(`${this.API_URL}/absence-notification/employee-details/${employeeId}/${language}`).pipe(
			map((employee) => {
				return {
					...employee,
					civilStatus: MaritalStatusEnumeration[employee.civilStatus],
					language: LanguageEnumeration[employee.language],
					gender: GenderEnumeration[employee.gender],
					country: 'country_' + employee.country,
				};
			})
		);
	}

	/**
	 * Search for employee within the company.
	 * @param companyId Company's unique identifier (not NEO Id)
	 * @param term String value which will be used for search over multiple employee's attributes
	 */
	searchEmployee(companyId: string): Observable<Option[]> {
		const url = `${this.API_URL}/absence-notification/search-employee/${companyId}`;
		return this.http.get<QueryResponse>(url).pipe(map((result) => result.records.map(toOption)));
	}

	/**
	 * Gets case data for quickSearch.
	 * @param code Unique absence identifier
	 */
	getCaseDetailsOption(code: string): Observable<Option> {
		return this.http.get<Option>(`${this.API_URL}/absence-notification/case-details-option/${code}`);
	}

	/**
	 * Gets case details for unique case code.
	 * @param code Unique absence identifier
	 */
	getCaseReferenceDetails(code: string): Observable<CaseReferenceDetails> {
		return this.http.get<CaseReferenceDetails>(`${this.API_URL}/absence-notification/reference-case-details/${code}`);
	}

	/**
	 * Search for existing absence by reference number (absence code).
	 * @param companyId Unique company identifier (not NEO Id)
	 * @param letters Letters of of the absence code
	 */
	searchCase(companyId: string): Observable<Option[]> {
		const url = `${this.API_URL}/absence-notification/search-case/${companyId}`;
		return this.http.get<QueryResponse>(url).pipe(map((result) => result.records.map(toOption)));
	}

	/**
	 * Posts absence model to be saved.
	 * @param absence Absence model from the form to be saved as 'NEW'
	 * @param contact Contact information about a user
	 * @param language Language chosen by a user
	 */
	save(absence: Absence, contact: ContactDetails, language: string): Observable<SaveResponse> {
		const formData = this.factory.create(absence, contact, language, false);
		return this.http.post<SaveResponse>(`${this.API_URL}/absence-notification`, formData);
	}

	/**
	 * Posts absence model to be saved.
	 * @param absence Absence model from the form to be saved as 'NEW'
	 * @param contact Contact information about a user
	 * @param language Language chosen by a user
	 */
	forceNew(absence: Absence, contact: ContactDetails, language: string): Observable<SaveResponse> {
		const formData = this.factory.create(absence, contact, language, true);
		return this.http.post<SaveResponse>(`${this.API_URL}/absence-notification`, formData);
	}

	/**
	 * Posts annex model to be saved.
	 * @param absence Absence model from the form to be saved as annex (PROLONGATION or CLOSED)
	 * @param contact Contact information about a user
	 * @param language Language chosen by a user
	 */
	saveAnnex(absence: Absence, contact: ContactDetails, language: string): Observable<SaveResponse> {
		const formData = this.factory.createAnnex(absence, contact, language);
		return this.http.post<SaveResponse>(`${this.API_URL}/absence-notification/annex`, formData);
	}
}
