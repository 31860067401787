import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { UserTitleEnumeration } from '../../model/user-title.enumeration';
import { UserFunctionEnumeration } from '../../model/user-function.enumeration';
import { CanDeactivateFormComponent, Option } from 'nc-shared';
import { UserPageAction, UserSelect } from '../../state/action';
import { Observable } from 'rxjs';
import { ContactDetails } from '../../model/contact-details';
import { State } from '../../../state/app.state';
import { EnumUtils } from 'nc-utils';
import { FlagEnumeration } from '../../../absence/model/enumerations/flag.enumeration';
import { validatePhone, validatePhoneForQuestions } from '../../validation/contact-information-validator';
import { PhoneUtils } from '../../../shared/utils/phone-utils.component';

@Component({
	templateUrl: './contact-information.component.html',
	styleUrls: ['./contact-information.component.scss'],
})
export class ContactInformationComponent extends CanDeactivateFormComponent implements OnInit {
	titles: Option[];
	functions: Option[];
	yesNoOptions: Option[];
	contact$: Observable<ContactDetails>;
	signPlusForFurtherQuestion = '+';

	constructor(private store: Store<State>, private formBuilder: UntypedFormBuilder) {
		super();
	}

	ngOnInit(): void {
		this.titles = EnumUtils.toItems(UserTitleEnumeration);
		this.functions = EnumUtils.toItems(UserFunctionEnumeration);
		this.yesNoOptions = EnumUtils.toItems(FlagEnumeration);

		this.contact$ = this.store.select(UserSelect.getContact);
		this.formGroup = this.formBuilder.group(
			{
				firstName: ['', [Validators.required]],
				lastName: ['', [Validators.required]],
				email: ['', [Validators.required, Validators.email]],
				phone: [this.signPlusForFurtherQuestion, [Validators.required, validatePhone()]],
				isPhoneForQuestions: [FlagEnumeration.yes, [Validators.required]],
				phoneForQuestions: [this.signPlusForFurtherQuestion],
				title: ['MALE', [Validators.required]],
				function: ['BROKER', [Validators.required]],
			},
			{
				validators: [validatePhoneForQuestions()],
			}
		);
	}

	onCancel = (): void => {
		this.store.dispatch(UserPageAction.cancel());
	};

	dispatchSubmitAction = (): void => {
		const contact = this.formGroup.getRawValue();

		if (contact.isPhoneForQuestions === FlagEnumeration.yes) {
			contact.phoneForQuestions = null;
		}

		this.store.dispatch(UserPageAction.verificationSubmit({ contact }));
	};

	onPhoneForQuestionsCheck(event: KeyboardEvent): void {
		const input = event.target as HTMLInputElement;
		const inputValue = input.value;
		if (!inputValue.startsWith(this.signPlusForFurtherQuestion)) {
			this.formGroup.controls.phoneForQuestions.patchValue(this.signPlusForFurtherQuestion + inputValue);
		}
	}
	onPhoneCheck(event: KeyboardEvent): void {
		const input = event.target as HTMLInputElement;
		PhoneUtils.formatPhoneNumber(input, this.formGroup);
	}
}
