import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AbsenceState } from '../absence.state';

export const ABSENCE_FEATURE_STATE_NAME = 'absence';
export const ABSENCE_FEATURE_STATE = createFeatureSelector<AbsenceState>(ABSENCE_FEATURE_STATE_NAME);

export const getErrors = createSelector(ABSENCE_FEATURE_STATE, (state) => state.errors);
export const getAbsence = createSelector(ABSENCE_FEATURE_STATE, (state) => state.absence);
export const getCompany = createSelector(ABSENCE_FEATURE_STATE, (state) => state.company);
export const getEmployee = createSelector(ABSENCE_FEATURE_STATE, (state) => state.employee);
export const getEmployees = createSelector(ABSENCE_FEATURE_STATE, (state) => state.employees);
export const getEmployeesWithCompanyCode = createSelector(ABSENCE_FEATURE_STATE, (state) => state.employeesWithCompanyCode);
export const getCompanyId = createSelector(ABSENCE_FEATURE_STATE, (state) => state.company.id);
export const getAbsenceReferenceNumber = createSelector(ABSENCE_FEATURE_STATE, (state) => state.absence.referenceNumber);
export const getSwitchedToProlongation = createSelector(ABSENCE_FEATURE_STATE, (state) => state.absence.isSwitchedToProlongation);
