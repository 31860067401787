import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { ErrorHandlerService } from '../../shared/service/error-handler.service';
import { UserCredentials } from '../model/user-credentials';
import { SignInResult } from '../model/sign-in-result';
import { UserAccess } from '../model/user-access';
import { ActionResponse } from 'nc-utils';
import { EnvironmentLoader } from '../../core/config/environment-loader';

@Injectable({ providedIn: 'root' })
export class AuthorizationService {
	constructor(private httpClient: HttpClient, private errorHandler: ErrorHandlerService, private environmentLoader: EnvironmentLoader) {}

	private readonly API_URL = this.environmentLoader.getEnvironment().API_URL;

	signIn(credentials: UserCredentials, language: string): Observable<SignInResult> {
		const url = `${this.API_URL}/public-form/sign-in`;
		const request = { model: { neoId: Number(credentials.contractNumber), name: credentials.first3Letters, language } };
		return this.httpClient.post<UserAccess>(url, request).pipe(map((access) => new SignInResult(access, credentials)));
	}

	signOut(): Observable<ActionResponse> {
		const url = `${this.API_URL}/public-form/abandon`;
		return this.httpClient.get<ActionResponse>(url);
	}
}
