import { Component, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Title } from '@angular/platform-browser';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { State } from './state/app.state';
import { ActivatedRoute, Router } from '@angular/router';
import { RouteChange } from './shared/route-change';
import { BaseComponent } from 'nc-shared';
import { SettingsAction, SettingsSelect } from './state/settings/action';
import { BreakpointObserver } from '@angular/cdk/layout';
import { MatSidenav } from '@angular/material/sidenav';

@Component({
	selector: 'ea-root',
	styleUrls: ['./app.component.scss'],
	templateUrl: './app.component.html',
	animations: [],
})
export class AppComponent extends BaseComponent implements OnInit {
	title$: Observable<any>;
	loading$: Observable<any>;
	mobile$: Observable<boolean>;
	language$: Observable<string>;
	isSidenavOpen = true;

	@ViewChild('sidenav') public sidenav: MatSidenav;

	constructor(
		private title: Title,
		private router: Router,
		private store: Store<State>,
		private activatedRoute: ActivatedRoute,
		private translateService: TranslateService,
		private breakpointObserver: BreakpointObserver
	) {
		super();
	}

	ngOnInit(): void {
		this.language$ = this.store.select(SettingsSelect.getLanguage);
		this.title$ = RouteChange.getTitle$(this.router, this.activatedRoute, this.translateService);
		this.loading$ = this.language$.pipe(switchMap((language) => this.translateService.use(language)));
		this.mobile$ = this.breakpointObserver.observe(['(max-width: 900px)']).pipe(map((state) => state.matches));

		this.title$.subscribe((newTitle) => this.title.setTitle(newTitle));
	}

	onLanguageChange = (language: string): void => this.store.dispatch(SettingsAction.setLanguage({ language }));

	sidenavButtonClick = (): void => {
		this.sidenav.toggle();
		this.isSidenavOpen = !this.isSidenavOpen;
	};
}
