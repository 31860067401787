import { Component } from '@angular/core';
import { Location } from '@angular/common';
import { EnvironmentLoader } from '../../../core/config/environment-loader';

@Component({
	templateUrl: './version.component.html',
	styleUrls: ['./version.component.scss'],
})
export class VersionComponent {
	appVersion = this.environmentLoader.getEnvironment().version;

	constructor(private location: Location, private environmentLoader: EnvironmentLoader) {}

	onBack = (): void => {
		this.location.back();
	};
}
