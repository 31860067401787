import { createAction, props } from '@ngrx/store';
import { UserAccess } from '../../model/user-access';
import { UserCredentials } from '../../model/user-credentials';

export const signInSubmitSuccess = createAction(
	'[Sign In API] Submit access credentials success',
	props<{ access: UserAccess; credentials: UserCredentials }>()
);
export const signInSubmitFailed = createAction('[Sign In API] Submit access credentials failed', props<{ error: string }>());

export const cancelSuccess = createAction('[Verification API] Cancel success');
export const cancelFailed = createAction('[Verification API] Cancel failed', props<{ error: string }>());

export const verifySuccess = createAction('[Verification API] Verify SMS code success');
export const setVerified = createAction('[Verification API] Set verification flag', props<{ verified: boolean }>());
export const verifyFailed = createAction('[Verification API] Verify SMS code failed', props<{ error: string }>());

export const resendSuccess = createAction('[Verification API] SMS resend success');
export const resendFailed = createAction('[Verification API] SMS resend failed', props<{ error: string }>());

export const verificationSubmitSuccess = createAction('[Verification API] Contact submit success');
export const verificationSubmitFailed = createAction('[Verification API] Contact submit failed', props<{ error: string }>());
