import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '../../../state/absence.state';
import { AbsenceSelectAction } from '../../../state/action';
import { Observable } from 'rxjs';
import { Company } from '../../../model/company';

@Component({
	templateUrl: './employer-dialog.component.html',
	styleUrls: ['./employer-dialog.component.scss'],
})
export class EmployerDialogComponent implements OnInit {
	employer$: Observable<Company>;

	constructor(private store: Store<State>) {}

	ngOnInit(): void {
		this.employer$ = this.store.select(AbsenceSelectAction.getCompany);
	}
}
