import { Component, Input } from '@angular/core';
import { Company } from '../../model/company';
import { EmployerDialogComponent } from '../dialog/employer-dialog/employer-dialog.component';
import { DialogService } from 'nc-utils';

@Component({
	selector: 'ea-employer-details',
	templateUrl: './employer-details.component.html',
	styleUrls: ['./employer-details.component.scss'],
})
export class EmployerDetailsComponent {
	@Input() company: Company;

	constructor(private dialogService: DialogService) {}

	open = () => this.dialogService.open(EmployerDialogComponent);
}
