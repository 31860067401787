import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
	templateUrl: './unauthorized-access-dialog.component.html',
	styleUrls: ['./unauthorized-access-dialog.component.scss'],
})
export class UnauthorizedAccessDialogComponent {
	message: string;
	title: string;
	icon: string;

	constructor(private router: Router, @Inject(MAT_DIALOG_DATA) data) {
		this.message = data.message;
		this.title = data.title;
		this.icon = data.icon;
	}
}
