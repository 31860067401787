import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AuthorizationService } from '../service/authorization.service';
import { UserApiAction, UserPageAction } from './action';
import { map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { State } from '../../state/app.state';
import { SettingsSelect } from '../../state/settings/action';
import { VerificationService } from '../service/verification.service';
import { DialogConfigurationUtils, DialogService, DialogType } from 'nc-utils';
import { NcInformationDialogComponent } from 'nc-information-dialog';
import { HrAppAccessRequestFormComponent } from '../component/hr-app-access-request-form/hr-app-access-request-form.component';
import { RequestConfirmationDialogComponent } from '../component/dialog/request-confirmation-dialog.component';
import { HrApplicationAccessRequestService } from '../service/hr-application-access-request.service';

@Injectable()
export class UserEffect {
	constructor(
		private router: Router,
		private actions$: Actions,
		private store: Store<State>,
		private dialogService: DialogService,
		private verificationService: VerificationService,
		private authorizationService: AuthorizationService,
		private hrApplicationAccessRequestService: HrApplicationAccessRequestService
	) {}

	signInSubmitEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(UserPageAction.signInSubmit),
			withLatestFrom(this.store.select(SettingsSelect.getLanguage)),
			mergeMap(([credentials, language]) => this.authorizationService.signIn(credentials, language)),
			map((result) =>
				result.access.granted
					? UserApiAction.signInSubmitSuccess({ ...result })
					: UserApiAction.signInSubmitFailed({ error: result.access.message })
			)
		);
	});

	signInSubmitSuccessEffect$ = createEffect(
		() => {
			return this.actions$.pipe(
				ofType(UserApiAction.signInSubmitSuccess),
				tap(() => {
					this.dialogService.close(DialogType.PROCESSING);
					this.router.navigateByUrl('/verification/contact');
				})
			);
		},
		{ dispatch: false }
	);

	signInSubmitFailedEffect$ = createEffect(
		() => {
			return this.actions$.pipe(
				ofType(UserApiAction.signInSubmitFailed),
				tap(() => {
					this.dialogService.close(DialogType.PROCESSING);
				})
			);
		},
		{ dispatch: false }
	);

	cancelEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(UserPageAction.cancel),
			mergeMap(() => this.authorizationService.signOut()),
			map((response) => (response.success ? UserApiAction.cancelSuccess() : UserApiAction.cancelFailed({ error: response.message })))
		);
	});

	cancelSuccessEffect$ = createEffect(
		() => {
			return this.actions$.pipe(
				ofType(UserApiAction.cancelSuccess),
				tap(() => {
					this.router.navigateByUrl('/');
				})
			);
		},
		{ dispatch: false }
	);

	cancelFailedEffect$ = createEffect(
		() => {
			return this.actions$.pipe(
				ofType(UserApiAction.cancelFailed),
				tap((action) => {
					this.dialogService.open(NcInformationDialogComponent, DialogConfigurationUtils.error(action.error));
				})
			);
		},
		{ dispatch: false }
	);

	verifyEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(UserPageAction.verify),
			withLatestFrom(this.store.select(SettingsSelect.getLanguage)),
			mergeMap(([action, language]) => this.verificationService.verify(action.code, language)),
			map((response) => (response.success ? UserApiAction.verifySuccess() : UserApiAction.verifyFailed({ error: response.message })))
		);
	});

	verifySuccessEffect$ = createEffect(
		() => {
			return this.actions$.pipe(
				ofType(UserApiAction.verifySuccess),
				tap(() => this.store.dispatch(UserApiAction.setVerified({ verified: true }))),
				tap(() => {
					this.dialogService.close(DialogType.PROCESSING);
					this.router.navigateByUrl('/absence/notification');
				})
			);
		},
		{ dispatch: false }
	);

	verifyFailedEffect$ = createEffect(
		() => {
			return this.actions$.pipe(
				ofType(UserApiAction.verifyFailed),
				tap((action) => {
					this.dialogService.close(DialogType.PROCESSING);
					this.dialogService.open(NcInformationDialogComponent, DialogConfigurationUtils.error(action.error));
				})
			);
		},
		{ dispatch: false }
	);

	resendEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(UserPageAction.resend),
			withLatestFrom(this.store.select(SettingsSelect.getLanguage)),
			mergeMap(([action, language]) => this.verificationService.resendSMS(language)),
			map((response) => (response.success ? UserApiAction.resendSuccess() : UserApiAction.resendFailed({ error: response.message })))
		);
	});

	resendFailedEffect$ = createEffect(
		() => {
			return this.actions$.pipe(
				ofType(UserApiAction.resendFailed),
				tap((action) => this.dialogService.open(NcInformationDialogComponent, DialogConfigurationUtils.error(action.error)))
			);
		},
		{ dispatch: false }
	);

	verificationSubmitEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(UserPageAction.verificationSubmit),
			withLatestFrom(this.store.select(SettingsSelect.getLanguage)),
			mergeMap(([action, language]) => this.verificationService.sendSMS(action.contact, language)),
			map((response) =>
				response.success ? UserApiAction.verificationSubmitSuccess() : UserApiAction.verificationSubmitFailed({ error: response.message })
			)
		);
	});

	verificationSubmitSuccessEffect$ = createEffect(
		() => {
			return this.actions$.pipe(
				ofType(UserApiAction.verificationSubmitSuccess),
				tap(() => {
					this.dialogService.close(DialogType.PROCESSING);
					this.router.navigateByUrl('/verification/sms');
				})
			);
		},
		{ dispatch: false }
	);

	verificationSubmitFailedEffect$ = createEffect(
		() => {
			return this.actions$.pipe(
				ofType(UserApiAction.verificationSubmitFailed),
				tap((action) => {
					this.dialogService.close(DialogType.PROCESSING);
					this.dialogService.open(NcInformationDialogComponent, DialogConfigurationUtils.error(action.error));
				})
			);
		},
		{ dispatch: false }
	);

	requestFormEffect$ = createEffect(
		() => {
			return this.actions$.pipe(
				ofType(UserPageAction.requestForm),
				switchMap((action) => this.hrApplicationAccessRequestService.sendEmail(action.data)),
				tap((response) => {
					if (response.success) {
						const dialogConfig = { disableClose: true };
						const dialogRef = this.dialogService.open(RequestConfirmationDialogComponent, dialogConfig);
						dialogRef.afterClosed().subscribe((result) => {
							this.dialogService.closeAll();
						});
					} else {
						this.dialogService.open(NcInformationDialogComponent, DialogConfigurationUtils.error(response.errors[0].code));
					}
				})
			);
		},
		{ dispatch: false }
	);

	openRequestFormDialogEffect$ = createEffect(
		() => {
			return this.actions$.pipe(
				ofType(UserPageAction.openRequestFormDialog),
				tap((action) => {
					const title = action.title;
					const dialogConfig = { disableClose: true, data: { title }, width: '20%' };
					this.dialogService.open(HrAppAccessRequestFormComponent, dialogConfig);
				})
			);
		},
		{ dispatch: false }
	);

	closeDialogEffect$ = createEffect(
		() => {
			return this.actions$.pipe(
				ofType(UserPageAction.closeDialog),
				tap(() => this.dialogService.closeAll())
			);
		},
		{ dispatch: false }
	);
}
