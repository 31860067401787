import { createAction, props } from '@ngrx/store';
import { ContactDetails } from '../../model/contact-details';
import { HrApplicationAccessRequest } from '../../model/hr-application-access-request';

export const signInSubmit = createAction('[Sign In] Submit', props<{ contractNumber: string; first3Letters: string; remember: boolean }>());
export const cancel = createAction('[Sign In] Cancel - Clear current access');
export const initialize = createAction('[Sign in] Initialize feature state - Clear token');

export const verificationSubmit = createAction('[Verification] Submit contact data', props<{ contact: ContactDetails }>());
export const verify = createAction('[Verification] Verify code', props<{ code: string }>());
export const resend = createAction('[Verification] Resend verification code');
export const requestForm = createAction('[Absence] PublicRequest form', props<{ data: HrApplicationAccessRequest }>());
export const openRequestFormDialog = createAction('[Absence] Open request form dialog', props<{ title }>());
export const closeDialog = createAction('[Settings] Close dialog');
