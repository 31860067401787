import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { FormValidation } from '../../shared/form-validation';
import { StringsUtils } from 'nc-utils';
import { FlagEnumeration } from '../../absence/model/enumerations/flag.enumeration';

const OK = null;
const REQUIRED = { required: true };
const PHONE_FOR_QUESTIONS_FORMAT_ERROR = { public_form_wrong_phone_number_format: true };
const PHONE_FORMAT_ERROR = { invalidFormatPhone: true };

const PHONE_NUMBER = /^\+[0-9]\d*$/;

const validatePhoneForQuestions = (): ValidatorFn => {
	return (group: AbstractControl): ValidationErrors | null => {
		const phoneForQuestions = group.get('phoneForQuestions');
		const isPhoneForQuestions = group.get('isPhoneForQuestions');

		if (isPhoneForQuestions.value === FlagEnumeration.no && StringsUtils.isEmpty(phoneForQuestions.value)) {
			FormValidation.addError(phoneForQuestions, REQUIRED);
		} else {
			FormValidation.removeError(phoneForQuestions, REQUIRED);
		}

		if (isPhoneForQuestions.value === FlagEnumeration.yes || PHONE_NUMBER.test(phoneForQuestions.value)) {
			FormValidation.removeError(phoneForQuestions, PHONE_FOR_QUESTIONS_FORMAT_ERROR);
		} else {
			FormValidation.addError(phoneForQuestions, PHONE_FOR_QUESTIONS_FORMAT_ERROR);
		}

		return OK;
	};
};
const validatePhone = (): ValidatorFn => {
	return (control: AbstractControl): ValidationErrors | null => {
		const value = control.value;
		if (!PHONE_NUMBER.test(value)) {
			return PHONE_FORMAT_ERROR;
		}
	};
};

export { validatePhoneForQuestions };
export { validatePhone };
