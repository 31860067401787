import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '../../../state/absence.state';
import { AbsenceService } from '../../../service/absence.service';
import { BaseCaseComponent } from '../base-case.component';

@Component({
	selector: 'ea-close-message',
	templateUrl: './close-message.component.html',
	styleUrls: ['./close-message.component.scss'],
})
export class CloseMessageComponent extends BaseCaseComponent implements OnInit {
	constructor(protected store: Store<State>, protected absenceService: AbsenceService) {
		super(store, absenceService);
	}
}
