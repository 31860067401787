<nc-confirmation-dialog titleLabel="insuredPerson" buttonLabel="close">
	<table class="readonly-form" *ngIf="employee$ | async as data">
		<tr>
			<td>{{ 'firstName' | translate }}:</td>
			<td>{{ data.firstName }}</td>
		</tr>
		<tr>
			<td>{{ 'lastName' | translate }}:</td>
			<td>{{ data.lastName }}</td>
		</tr>
		<tr *ngIf="data.socialSecurityNumber">
			<td>{{ 'socialSecurityNumber' | translate }}:</td>
			<td>{{ data.socialSecurityNumber }}</td>
		</tr>
		<tr>
			<td>{{ 'gender' | translate }}:</td>
			<td>{{ data.gender | translate }}</td>
		</tr>
		<tr>
			<td>{{ 'maritalStatus' | translate }}:</td>
			<td>{{ data.civilStatus | translate }}</td>
		</tr>
		<tr>
			<td>{{ 'language' | translate }}:</td>
			<td>{{ data.language | translate }}</td>
		</tr>
		<tr>
			<td>{{ 'birthDate' | translate }}:</td>
			<td>{{ data.birthDate | date : 'dd.MM.yyyy' }}</td>
		</tr>
		<tr>
			<td>&nbsp;</td>
			<td>&nbsp;</td>
		</tr>
		<tr>
			<td>{{ 'pkProInsuranceEntryDate' | translate }}:</td>
			<td>{{ data.pkProInsuranceEntryDate | date : 'dd.MM.yyyy' }}</td>
		</tr>
		<tr>
			<td>{{ 'pkProInsuranceExitDate' | translate }}:</td>
			<td>{{ data.pkProInsuranceExitDate | date : 'dd.MM.yyyy' }}</td>
		</tr>
		<tr *ngIf="data.email || data.mobilePhone">
			<td>&nbsp;</td>
			<td>&nbsp;</td>
		</tr>
		<tr *ngIf="data.email">
			<td>{{ 'email' | translate }}:</td>
			<td>{{ data.email }}</td>
		</tr>
		<tr *ngIf="data.mobilePhone">
			<td>{{ 'phoneNumber' | translate }}:</td>
			<td>{{ data.mobilePhone }}</td>
		</tr>
		<tr>
			<td>&nbsp;</td>
			<td>&nbsp;</td>
		</tr>
		<tr>
			<td>{{ 'street' | translate }}:</td>
			<td>{{ data.street }}</td>
		</tr>
		<tr>
			<td>{{ 'zip' | translate }}:</td>
			<td>{{ data.zip }}</td>
		</tr>
		<tr>
			<td>{{ 'city' | translate }}:</td>
			<td>{{ data.city }}</td>
		</tr>
		<tr>
			<td>{{ 'country' | translate }}:</td>
			<td>{{ data.country | translate }}</td>
		</tr>
		<tr>
			<td>{{ 'canton' | translate }}:</td>
			<td>{{ data.residenceCanton }}</td>
		</tr>
	</table>
</nc-confirmation-dialog>
