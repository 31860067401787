import { SharedModule } from '../shared/shared.module';
import { DocumentComponent } from './document.component';
import { NgModule } from '@angular/core';
import { NcMainContentModule } from 'nc-main-content';
import { NcPdfViewerModule } from 'nc-pdf-viewer';

@NgModule({
	imports: [SharedModule, NcMainContentModule, NcPdfViewerModule],
	declarations: [DocumentComponent],
})
export class DocumentModule {}
