import { UserAccess } from './user-access';
import { UserCredentials } from './user-credentials';

export class SignInResult {
	access: UserAccess;
	credentials: UserCredentials;

	constructor(access: UserAccess, credentials: UserCredentials) {
		this.access = access;
		this.credentials = credentials;
	}
}
