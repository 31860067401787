import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { CaseReferenceDetails } from '../../model/case-reference-details/case-reference-details';
import { Store } from '@ngrx/store';
import { State } from '../../state/absence.state';
import { AbsenceService } from '../../service/absence.service';
import { distinctUntilChanged, filter, map, mergeMap, startWith, switchMap, takeUntil, withLatestFrom } from 'rxjs/operators';
import { AbsencePageAction, AbsenceSelectAction } from '../../state/action';
import { BaseComponent, Option } from 'nc-shared';
import { StringsUtils } from 'nc-utils';
import { TableData } from 'nc-datatable';
import { Company } from '../../model/company';

@Component({
	template: '',
})
export class BaseCaseComponent extends BaseComponent implements OnInit {
	@Input() formGroup: UntypedFormGroup;

	caseDetails$: Observable<CaseReferenceDetails>;
	incapacities$: Observable<TableData>;
	selectedCase: UntypedFormControl;

	employeesWithCompanyCode$: Observable<Option[]>;
	company: Company;

	constructor(protected store: Store<State>, protected absenceService: AbsenceService) {
		super();
	}

	fetchCaseOption = (value) => of(value).pipe(switchMap((code) => this.absenceService.getCaseDetailsOption(code)));

	ngOnInit(): void {
		this.selectedCase = this.formGroup.get('referenceNumber') as UntypedFormControl;
		this.employeesWithCompanyCode$ = this.store.select(AbsenceSelectAction.getEmployeesWithCompanyCode);

		this.fetchEmployees();

		this.caseDetails$ = this.selectedCase.valueChanges.pipe(
			startWith(this.selectedCase.value),
			takeUntil(this.destroy$),
			distinctUntilChanged(),
			mergeMap((code) => (StringsUtils.isNotEmpty(code) ? this.absenceService.getCaseReferenceDetails(code) : of({} as CaseReferenceDetails)))
		);

		this.incapacities$ = this.caseDetails$.pipe(
			map((caseDetails) => (caseDetails?.incapacities ? { records: caseDetails.incapacities } : { records: [] }))
		);
	}

	displayFunc = (option: Option) => (option ? option.text.split(';')[0] : '');

	fetchEmployees(): void {
		this.store
			.select(AbsenceSelectAction.getCompany)
			.pipe(
				takeUntil(this.destroy$),
				filter((company) => company.id !== '0')
			)
			.subscribe((company) => {
				this.company = company;
				this.store.dispatch(AbsencePageAction.searchCase({ companyId: this.company.id }));
			});
	}

	filterItems = (term) => {
		if (term.length > 1) {
			let employeesRes: Option[] = [];
			this.employeesWithCompanyCode$
				.pipe(filter((employeesWithCompanyCode) => employeesWithCompanyCode != null && employeesWithCompanyCode.length > 0))
				.subscribe((employees) => {
					employeesRes = employees.filter((item) => item.text.toLowerCase().includes(term.toLowerCase()));
				});

			return of(employeesRes);
		}
	};
}
